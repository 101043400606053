import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { ICandidateUrl, TCandidateUrlType } from '../../../../../definitions/entities.definition';
import CustomEditableValue from '../../../../../components/CustomInput/CustomEditableValue';
import { EIcons, IconFactory } from '../../../../../components/Icons/IconFactory';
import { ClientApi } from '../../../../../requests/ClientApi';
import { postCandidateUrlRouteConfig } from '../../../../../requests/routes';
import { setCandidatePageAddUrlToFormAction } from '../../../../../redux/candidatePage/candidatePage.actions';
import { registerError } from '../../../../../redux/error/error.actions';
import CandidateUrlItemView from './components/CandidateUrlItemView';
import CustomTextField from '../../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../../translation/translate.utils';
import CandidateUrlTypeDropDown
    from '../../../../../components/CustomInput/CustomDropDownInput/CandidateUrlTypeDropDown';
import { getSuggestionAction } from '../../../../../redux/entities/entities.actions';
import CustomClickableIcon from '../../../../../components/CustomInput/CustomClickableIcon';
import { addOnBrainzPluginHandler, sendUrlToBrainzPlugin } from '../../../../../utils/components.utils';
import { CircularProgress } from '@material-ui/core';

interface IProps {
    candidateId?: number;
    records: ICandidateUrl[];
    suggestionId?: number;
}

const CandidateUrlListView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const urls = props.records;
    const suggestionId = props.suggestionId;

    const [importInProgress, setImportInProgress] = useState(false);
    const [urlText, setUrlText] = useState('');
    const [comment, setComment] = useState('');
    const [type, setType] = useState<TCandidateUrlType>('linkedIn');

    addOnBrainzPluginHandler((event: MessageEvent) => {
        setImportInProgress(false);
    });

    const onAdd = () => {
        if (!candidateId) {
            dispatch(setCandidatePageAddUrlToFormAction({
                id: 0,
                url: urlText,
                comment,
                type
            }));
            return Promise.resolve();
        }

        return ClientApi.request(postCandidateUrlRouteConfig, {
            token,
            candidateId,
            url: urlText,
            type,
            comment
        }).then((mail: ICandidateUrl) => {
            setComment('');
            setUrlText('');

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageAddUrlToFormAction(mail));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
        });
    }

    return <>
        <div className={"flexContainerColumn"} style={{overflow: 'auto', maxHeight: 300}}>
            {urls.map((url, index) => {
                return <div key={url.id} className={"flexContainerRow alignVerticalCenter"}>
                    <CandidateUrlItemView
                        key={url.id}
                        index={index}
                        candidateId={candidateId}
                        suggestionId={suggestionId}
                        url={url}
                    />
                    {importInProgress &&
                    <>
                        <CircularProgress color="inherit" size={20}/>
                        <div style={{width: 8}}></div>
                    </>
                    }
                    {(!importInProgress && url.type === "linkedIn") &&
                        <CustomClickableIcon
                            tooltip={"Daten aus LinkedIn auslesen"}
                            onClick={() => {
                                setImportInProgress(true);
                                sendUrlToBrainzPlugin(url.url)
                            }}
                            icon={EIcons.Download}
                        />
                    }
                </div>
            })}
        </div>
        <CustomEditableValue
            theme={'light'}
            input={<div className={"flexContainerColumn flex1 gap5"}>
                <CustomTextField
                    required
                    size={'small'}
                    value={urlText}
                    onChange={(value) => setUrlText(value)}
                    label={translate('misc.url')}
                />
                <CandidateUrlTypeDropDown
                    value={type}
                    disablePortal
                    size={'small'}
                    onChange={(event) => setType(event.target.value)}
                />
                <CustomTextField
                    multiline
                    size={'small'}
                    rows={5}
                    label={translate('misc.comment')}
                    value={comment}
                    onChange={(value) => setComment(value)}
                />
            </div>}
            emptyValue={
                <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.Add)}
                    Url hinzufügen
                </div>
            }
            onSave={onAdd}
        />
    </>
}

export default CandidateUrlListView;
