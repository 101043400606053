import React from 'react';
import { IPluginCandidate } from '../../../definitions/entities.definition';
import { Chip, Divider } from '@material-ui/core';

interface IProps {
    importData: IPluginCandidate;
}

const CandidateImportExternalForm: React.FC<IProps> = (props) => {
    const importData = props.importData;

    return <>
        <div className={"flexContainerColumn flex1"}>
            <div className={"flexContainerColumn flexAlignStart overflow"}>
                <img src={importData.imageUrl} height={200} style={{marginLeft:5}}/>
                {(importData.skills.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider style={{marginTop:5}}/>
                        <h4 style={{marginLeft:0, margin: 5}}>Skills ({importData.skills.length})</h4>
                        <div className={"flexContainerRow gap5 flexWrap"}>
                            {importData.skills.map((externalSkill) => <Chip
                                key={externalSkill.skill}
                                color={'primary'}
                                style={{height: 26}}
                                label={<div className={"flex flexCentered"}>
                                    {externalSkill.skill}
                                </div>}
                            />)}
                        </div>
                    </div>
                }
                {(importData.languages.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider style={{marginTop:5}}/>
                        <h4 style={{margin: 5}}>Sprachen ({importData.languages.length})</h4>
                        <div className={"flexContainerRow gap5 flexWrap"}>
                            {importData.languages.map((externalLanguage) => <Chip
                                key={externalLanguage.language}
                                color={'primary'}
                                label={<div className={"flexContainerColumn"}>
                                    <div className={"flex1 ellipseText"}>{externalLanguage.language}</div>
                                    <div className={"formContent"} style={{fontSize: 10}}>
                                        {externalLanguage.level}
                                    </div>
                                </div>}
                            />)}
                        </div>
                    </div>
                }
                {(importData.experience.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider style={{marginTop:5}}/>
                        <h4 style={{marginLeft:0, margin: 5}}>Beruflicher Werdegang ({importData.experience.length})</h4>
                        <div className={"flexContainerColumn gap5 paddingLeft10"}>
                            {importData.experience.map((experience) => <>
                                <div className={"flexContainerColumn"} key={experience.jobTitle + experience.company}>
                                    <div className={"formLabelSmall"}>
                                        {experience.jobTitle}
                                    </div>
                                    <div className={"formContent"}>
                                        {experience.company}
                                        {experience.city &&
                                        <>
                                            <br/>
                                            {experience.city}
                                        </>
                                        }
                                        <br/>{experience.fromDate} - {experience.toDate}
                                        {experience.skills.length > 0 &&
                                        <>
                                            <br/>
                                            {experience.skills.length} Skills
                                        </>
                                        }
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                }
                {(importData.education.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider/>
                        <h4 style={{margin: 5}}>Ausbildung ({importData.education.length})</h4>
                        <div className={"flexContainerColumn gap5 paddingLeft10"}>
                            {importData.education.map((education) => <>
                                <div className={"flexContainerColumn"} key={education.title + education.school}>
                                    <div className={"formLabelSmall"}>
                                        {education.title}
                                    </div>
                                    <div className={"formContent"}>
                                        {education.school}
                                        {education.fromDate &&
                                        <>
                                            <br/>
                                            {education.fromDate} - {education.toDate}
                                        </>
                                        }
                                        {education.grade && <><br/>Note: {education.grade}</>}
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                }
                {(importData.publications.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider/>
                        <h4 style={{margin: 5}}>Publikationen ({importData.publications.length})</h4>
                        <div className={"flexContainerColumn gap5 paddingLeft10"}>
                            {importData.publications.map((publication) => <>
                                <div className={"flexContainerColumn"} key={publication.title}>
                                    <div className={"formLabelSmall"}>
                                        {publication.title}
                                    </div>
                                    <div className={"formContent"}>
                                        {publication.organisation}
                                        {publication.date &&
                                        <>
                                            <br/>
                                            {publication.date}
                                        </>
                                        }
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                }
                {(importData.certifications.length > 0) &&
                    <div className={"flexContainerColumn flex1"}>
                        <Divider/>
                        <h4 style={{margin: 5}}>Zertifikate und Bescheinigungen ({importData.certifications.length})</h4>
                        <div className={"flexContainerColumn gap5 paddingLeft10"}>
                            {importData.certifications.map((certification) => <>
                                <div className={"flexContainerColumn"} key={certification.title}>
                                    <div className={"formLabelSmall"}>
                                        {certification.title}
                                    </div>
                                    <div className={"formContent"}>
                                        {certification.organisation}
                                        {certification.date &&
                                        <>
                                            <br/>
                                            {certification.date}
                                        </>
                                        }
                                        {certification.skills.length > 0 &&
                                        <>
                                            <br/>
                                            {certification.skills.length} Skills
                                        </>
                                        }
                                    </div>
                                </div>
                            </>)}
                        </div>
                    </div>
                }
            </div>
        </div>
    </>;
};

export default CandidateImportExternalForm;
