import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerError, registerInfo } from '../../../../../../redux/error/error.actions';
import { EIcons } from '../../../../../../components/Icons/IconFactory';
import { deleteCandidateUrlRouteConfig, putCandidateUrlRouteConfig } from '../../../../../../requests/routes';
import { IListActionButtonConfig } from '../../../../../../definitions/components.definitions';
import {
    setCandidatePageDeleteUrlFromFormAction,
    setCandidatePageUpdateUrlInFormAction,
} from '../../../../../../redux/candidatePage/candidatePage.actions';
import CustomEditableValue from '../../../../../../components/CustomInput/CustomEditableValue';
import CustomTextField from '../../../../../../components/CustomInput/CustomTextField';
import { getToken } from '../../../../../../selectors/app.selectors';
import CustomContextMenu from '../../../../../../components/CustomContextMenu/CustomContextMenu';
import {
    ICandidateMailAddress,
    ICandidateUrl,
    TCandidateUrlType,
} from '../../../../../../definitions/entities.definition';
import { ClientApi } from '../../../../../../requests/ClientApi';
import Nl2brCellRenderer from '../../../../../../components/CustomCellRenderer/Nl2brCellRenderer';
import { translate } from '../../../../../../translation/translate.utils';
import UrlCellRenderer from '../../../../../../components/CustomCellRenderer/UrlCellRenderer';
import CandidateSourceRenderer from '../../../../../../components/CustomCellRenderer/CandidateSourceRenderer';
import CandidateUrlTypeDropDown
    from '../../../../../../components/CustomInput/CustomDropDownInput/CandidateUrlTypeDropDown';
import { getSuggestionAction } from '../../../../../../redux/entities/entities.actions';
import { InputAdornment } from '@material-ui/core';
import CustomClickableIcon from '../../../../../../components/CustomInput/CustomClickableIcon';
import { fixUrl } from '../../../../../../utils/application.utils';

interface IProps {
    index: number;
    candidateId?: number;
    suggestionId?: number;
    url: ICandidateUrl;
}

const CandidateUrlItemView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const suggestionId = props.suggestionId;
    const url = props.url;
    const index = props.index;

    const [urlText, setUrlText] = useState(url.url);
    const [type, setType] = useState<TCandidateUrlType>('linkedIn');
    const [comment, setComment] = useState(url.comment);

    const onDelete = () => {
        if (!candidateId) {
            dispatch(setCandidatePageDeleteUrlFromFormAction(index));
            return;
        }

        ClientApi.request(deleteCandidateUrlRouteConfig, {
            token,
            candidateId,
            id: url.id,
        }).then(() => {
            dispatch(registerInfo('Datensatz wurde gelöscht.'));

            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            dispatch(setCandidatePageDeleteUrlFromFormAction(index));
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht gelöscht werden'));
        });
    };

    const onUpdate = () => {
        if (urlText === url.url && comment === url.comment && type === url.type) {
            return Promise.resolve();
        }

        const updateInStore = (index: number, phone: ICandidateUrl) => {
            dispatch(setCandidatePageUpdateUrlInFormAction(index, phone));
        };

        const putUrl: ICandidateUrl = {
            id: url.id,
            url: urlText,
            type,
            comment,
            type
        }

        if (!candidateId) {
            updateInStore(index, putUrl);
            return Promise.resolve();
        }

        return ClientApi.request(putCandidateUrlRouteConfig, {
            token,
            candidateId,
            ...putUrl,
        }).then((phone: ICandidateUrl) => {
            if (suggestionId) {
                dispatch(getSuggestionAction(token, suggestionId));
                return;
            }

            updateInStore(index, phone);
        }).catch(() => {
            dispatch(registerError('Datensatz konnte nicht aktualisiert werden'));
        });
    };
    const contextMenu: IListActionButtonConfig[] = [{
        icon: EIcons.Remove,
        action: () => onDelete(),
        translationKey: 'tables.openDeleteView',
    }, {
        icon: EIcons.Copy,
        action: () => {
            navigator.clipboard.writeText(url.url);
            dispatch(registerInfo('Url wurde kopiert'));
        },
        translationKey: 'misc.copy',
    }];

    return <>
        <CustomContextMenu
            index={index}
            entityId={url.id}
            entity={url}
            menuHeading={(entity) => {
                const entityCast = entity as ICandidateMailAddress;
                return entityCast.mailAddress;
            }}
            menuItems={contextMenu}
        >
            <CustomEditableValue
                key={url.id}
                theme={'light'}
                input={<div className={"flexContainerColumn flex1 gap5"}>
                    <CustomTextField
                        required
                        autoFocus
                        size={'small'}
                        value={urlText}
                        onChange={(value) => setUrlText(value)}
                        label={translate('misc.url')}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <CustomClickableIcon onClick={() => {
                                    navigator.clipboard.writeText(urlText);
                                    dispatch(registerInfo('Url wurde kopiert'));
                                }} icon={EIcons.Copy}/>
                            </InputAdornment>
                        }}
                    />
                    <CandidateUrlTypeDropDown
                        value={type}
                        disablePortal
                        size={'small'}
                        onChange={(event) => setType(event.target.value)}
                    />
                    <CustomTextField
                        multiline
                        size={'small'}
                        rows={5}
                        label={translate('misc.comment')}
                        value={comment}
                        onChange={(value) => setComment(value)}
                    />
                </div>}
                value={url.url}
                onSave={onUpdate}
            >
                <div className={'flexContainerRow alignVerticalCenter'}>
                    <UrlCellRenderer hideLaunchIcon value={
                        <>
                            <CandidateSourceRenderer renderHomepageAsWebsite source={url.type}/>
                            &nbsp;
                            {(url.type === 'further' || url.type === 'homepage') ? url.url : (new URL(fixUrl(url.url))).pathname}
                        </>
                    } url={url.url} />
                </div>
                {url.comment &&
                    <div className={'formContent'}>
                        <Nl2brCellRenderer value={url.comment}/>
                    </div>
                }
            </CustomEditableValue>
        </CustomContextMenu>
    </>;
};

export default CandidateUrlItemView;
