import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { IStore } from '../../definitions/redux/store.definitions';
import { addInvoiceAction } from '../../redux/entities/entities.actions';
import { IInvoiceForApi } from '../../definitions/entities.definition';
import {
    setBillingPageAddInvoiceDialogAction,
    setBillingPageInvoiceFormAction,
} from '../../redux/billingPage/billingPage.actions';
import InvoiceItemsView from '../EditViews/Invoice/components/InvoiceItemsView';
import { useRecalculate } from '../EditViews/Invoice/components/InvoiceAddEditView.hook';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import { translate } from '../../translation/translate.utils';
import { resetFormValidation } from '../../redux/error/error.actions';
import { getToken } from '../../selectors/app.selectors';
import InvoiceHeaderView from '../EditViews/Invoice/components/InvoiceHeaderView';
import InvoiceFooterView from '../EditViews/Invoice/components/InvoiceFooterView';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { initialInvoiceFormState } from '../../definitions/billingPage.definitions';

const InvoiceAddView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const addInvoice = useSelector((store: IStore) => Boolean(store.billingPage.addInvoice));
    const invoiceForm = useSelector((state: IStore) => state.billingPage.invoiceForm);
    const invoiceFormItems = useSelector((state: IStore) => state.billingPage.invoiceForm.items);

    useRecalculate();

    const onClose = () => {
        dispatch(setBillingPageAddInvoiceDialogAction(false));
        dispatch(resetFormValidation());
    }

    useEffect(() => {
        dispatch(setBillingPageInvoiceFormAction(initialInvoiceFormState));
    },[])

    const onSave = () => {
        const invoiceForApi: IInvoiceForApi = {
            number: invoiceForm.numberDisabled ? undefined : invoiceForm.number,
            date: moment(invoiceForm.date).format("Y-MM-DD"),
            dueDate: moment(invoiceForm.dueDate).format("Y-MM-DD"),
            companyId: invoiceForm.company?.id,
            companyLocationId: invoiceForm.companyLocation?.id,
            companyContactId: invoiceForm.companyContact ? invoiceForm.companyContact.id : null,
            companyName: invoiceForm.companyName,
            address: invoiceForm.address,
            addressLine2: invoiceForm.addressLine2,
            zipCode: invoiceForm.zipCode,
            city: invoiceForm.city,
            discountInPercent: invoiceForm.discountInPercent,
            discountSum: invoiceForm.discountSum,
            comment: invoiceForm.comment,
            items: invoiceFormItems,
            termsOfPaymentId: invoiceForm.termsOfPaymentId,
            remindOfPaymentCreated: invoiceForm.remindOfPaymentCreated,
            remindOfPaymentDate: invoiceForm.remindOfPaymentDate ? moment(invoiceForm.remindOfPaymentDate).format("Y-MM-DD") : null,
            remindOfPaymentDueDate: invoiceForm.remindOfPaymentDueDate ? moment(invoiceForm.remindOfPaymentDueDate).format("Y-MM-DD") : null
        };

        dispatch(addInvoiceAction(token, invoiceForApi));
        return;
    }

    if (!addInvoice) {
        return null;
    }

    return <>
        <CustomDialog fullWidth maxWidth={"md"} open onClose={onClose}>
            <CustomDialogHeader string="Rechnung hinzufügen" onClose={onClose} />
            <div style={{ display:"flex", marginTop:-3, flexDirection: "column", height: "calc(100% - 70px)", width: "100%"}}>
                <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
                    <InvoiceHeaderView formState={invoiceForm} addInvoice/>
                    <InvoiceItemsView items={invoiceFormItems} />
                    <InvoiceFooterView formState={invoiceForm} />
                </div>
                <CustomDialogActions
                    onClose={onClose}
                    buttons={[{
                        void: () => onSave(false),
                        label: translate('misc.buttons.save'),
                    }]}
                />
            </div>
        </CustomDialog>
    </>;
}
export default InvoiceAddView;

