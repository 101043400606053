import React from 'react';
import { EIcons, IconFactory } from '../components/Icons/IconFactory';
import { Badge } from '@material-ui/core';
import { themeBlue, themeGreen, themeOrange } from '../theme/theme';
import { EEntityView, IEditViewState } from '../definitions/ui.definitions';
import {
    ICandidate,
    ICompany,
    ICompanyContact, IInvoice,
    IRecruiting,
    ISuggestion,
    IVacancy,
    IWebVacancy,
    TBrainzEntity,
} from '../definitions/entities.definition';
import { translate } from '../translation/translate.utils';
import { ERoutes } from '../definitions/app.definitions';

export const getEditViewUrl = (viewData: IEditViewState, title: null|string) => {
    const urlMapping = new Map<EEntityView, string>([
        [EEntityView.vacancy, ERoutes.Vacancy],
        [EEntityView.candidate, ERoutes.Candidate],
        [EEntityView.webVacancy, ERoutes.WebVacancy],
        [EEntityView.recruiting, ERoutes.Recruiting],
        [EEntityView.company, ERoutes.Company],
        [EEntityView.suggestion, ERoutes.Suggestion],
        [EEntityView.invoice, ERoutes.Invoice]
    ]);

    return `${window.location.origin.toString()}${urlMapping.get(viewData.view)}/${viewData.entityId}/${title?.replace(' ', '_')}`
}

export const getEmployeeImageUrl = (employeeId: number) => {
    switch (employeeId) {
        case 0:
            return "images/peagle.png";
        case 1:
            return "employeeImages/daniel.jpeg";
        case 2:
            return "employeeImages/steve.jpeg";
        case 3:
            return "employeeImages/vronineu.jpeg";
        case 4:
            return "employeeImages/maik.jpeg";
        case 9:
            return "employeeImages/uli.jpeg";
    }

    return '';
}

export const getEntityViewTitle = (view: EEntityView, entity: TBrainzEntity) => {
    switch (view) {
        case EEntityView.vacancy: {
            const entityCast = entity as IVacancy;
            return translate('misc.vacancy') + ' / ' + entityCast.title;
        }
        case EEntityView.companyContact: {
            const entityCast = entity as ICompanyContact;
            return translate('misc.companyContact') + ' / ' + entityCast.fullName;
        }
        case EEntityView.company: {
            const entityCast = entity as ICompany;
            return translate('misc.company') + ' / ' + entityCast.name;
        }
        case EEntityView.recruiting: {
            const entityCast = entity as IRecruiting;
            return translate('misc.process') + ' / ' + entityCast.candidate.fullName + ' bei ' + entityCast.company.name;
        }
        case EEntityView.candidate: {
            const entityCast = entity as ICandidate;
            return translate('misc.candidate') + ' / ' + entityCast.fullName;
        }
        case EEntityView.suggestion: {
            const entityCast = entity as ISuggestion;
            return 'Favorit / ' + entityCast.candidate.fullName;
        }
        case EEntityView.invoice: {
            const entityCast = entity as IInvoice;
            return 'Rechnung / ' + entityCast.number;
        }
        case EEntityView.webVacancy: {
            const entityCast = entity as IWebVacancy;
            return translate('misc.webVacancy') + ' / ' + entityCast.title;
        }
    }
    return null;
};

export const getTabColorByParentViewType = (parentView: undefined|EEntityView, fallbackColor: string) => {
    if (!parentView) {
        return fallbackColor;
    }

    if (parentView === EEntityView.candidate) {
        return themeGreen.palette.primary.main;
    }

    if (parentView === EEntityView.company) {
        return themeBlue.palette.primary.main;
    }

    return themeOrange.palette.primary.main;
}

export const getTabCountLabel = (total: number, open: number, success?: number) => {
    if (total === 0) {
        return <>(0)</>;
    }

    const getCountLabel = () => {
        if (open === total) {
            return <>{total}</>
        }

        if (open === 0) {
            return <>{total}</>
        }

        return <>{open}/{total}</>
    }

    return <>
        ({getCountLabel()})
        {Boolean(success && success > 0) &&
            <>
                <Badge badgeContent={success}>
                    { IconFactory.getIconComponent(EIcons.EmojiEvents, {color: 'GOLD'}) }
                </Badge>
            </>
        }
    </>
}
