import React from 'react';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import {
    ECandidateSource,
    EEntities,
    EMailSendStatus,
    ERecruitingAbortedBy,
    ERecruitingDeclineReason,
    ERecruitingStatus,
    ERecruitingType,
    ICompany,
    ICompanyContactPartial,
    ICompanyLocationPartial,
    ICompanyPartial,
    IEmployeePartial,
    IRecruiting,
    IRecruitingForAPI,
    IVacancyPartial,
    IWebVacancyPartial,
} from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import RecruitingStatusDropDown from '../../../../components/CustomInput/CustomDropDownInput/RecruitingStatusDropDown';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import RecruitingAbortedByDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/RecruitingAbortedByDropDown';
import { updateRecruitingAction } from '../../../../redux/entities/entities.actions';
import CompanyContactAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyContactAutocompleteDropDown';
import moment from 'moment';
import { setUiCompanyContactAddDataAction, setUiCompanyLocationAddDataAction } from '../../../../redux/ui/ui.actions';
import { COLOR_YELLOW } from '../../../../theme/theme';
import { registerError, resetFormValidation } from '../../../../redux/error/error.actions';
import {
    setRecruitingPageCreateCongratulationMailAction,
    setRecruitingPageRecruitingToDeleteAction,
} from '../../../../redux/recruitingPage/recruitingPage.actions';
import { momentToDate } from '../../../../utils/date.utils';
import autobind from 'autobind-decorator';
import CompanyLocationAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyLocationAutocompleteDropDown';
import NumberDropDown from '../../../../components/CustomInput/CustomDropDownInput/NumberDropDown';
import LegacyCompanyAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyAutocompleteDropDown';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { connect, ConnectedProps } from 'react-redux';
import { userHasRole2 } from '../../../../selectors/employee.selectors';
import { ROLE_ADMIN } from '../../../../definitions/actor.definitions';
import RecruitingDeclineReasonDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/RecruitingDeclineReasonDropDown';
import BillingConfigurationElement from '../../../Shared/BillingSetting/BillingConfigurationElement';
import { EBillingType } from '../../../../definitions/billingConfiguration.definitions';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import { Divider } from '@material-ui/core';
import CustomRadioGroupInput from '../../../../components/CustomInput/CustomRadioGroupInput';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import WebVacancyAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/WebVacancyAutocomplete';
import CompanyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import RecruitingSharedDocumentAutocomplete from '../RecruitingSharedDocumentAutocomplete';
import LegacyYesNoDropDown from '../../../../components/CustomInput/CustomDropDownInput/LegacyYesNoDropDown';
import EmployeeAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';

interface IProps extends PropsFromRedux {
    recruiting: IRecruiting;
    abort?: boolean;
    contract?: boolean;
}

type TState = {
    suggestedFor: 'vacancy'|'webVacancy'|'initiative';
    employee: IEmployeePartial;
    suggestedBy: IEmployeePartial|null;
    company: ICompanyPartial;
    vacancy?: null|IVacancyPartial;
    webVacancy?: null|IWebVacancyPartial;
    url?: string;
    partner?: ICompanyPartial;
    companyLocation?: ICompanyLocationPartial;
    responsibleContact?: ICompanyContactPartial;
    contractSigningDate?: Date;
    contractBeginningDate?: Date;
    endOfTrialPeriod?: Date;
    status: ERecruitingStatus;
    source: ECandidateSource;
    desiredSalary?: number;
    contractSalary?: number;
    dateOfAbort?: Date;
    abortedBy?: ERecruitingAbortedBy;
    declineReason?: ERecruitingDeclineReason;
    abortComment?: string;
    contractComment?: string;
    candidateFollowupDate?: Date;
    comment?: string;
    finishedTrialPeriod?: boolean;
    recommendationBy?: string;
    projectPlanedEnd?: Date;
    projectEnd?: Date;
    projectCommissionPercent?: number;
    projectValuedHoursPerMonth?: number;
    projectInvoiceCreationDay?: number;
    projectInvoiceCreationInterval?: number;
    sharedDocumentIds: number[];
}

class RecruitingEditForm extends React.PureComponent<IProps, TState> {
    constructor(props: IProps) {
        super(props);

        const recruiting = props.recruiting;

        this.state = {
            suggestedFor: recruiting.suggestedFor,
            employee: recruiting.responsibleEmployee,
            suggestedBy: recruiting.suggestedBy,
            company: recruiting.company,
            vacancy: recruiting.vacancy,
            webVacancy: recruiting.webVacancy,
            url: recruiting.url,
            partner: recruiting.partner,
            companyLocation: recruiting.companyLocation,
            responsibleContact: recruiting.responsibleContact,
            dateOfAbort: props.abort ? new Date() : (recruiting.dateOfAbort ? new Date(recruiting.dateOfAbort) : undefined),
            contractSigningDate: recruiting.contract && recruiting.contract.contractSigningDate ? new Date(recruiting.contract.contractSigningDate) : undefined,
            contractBeginningDate: recruiting.contract && recruiting.contract.contractBeginningDate ? new Date(recruiting.contract.contractBeginningDate) : undefined,
            endOfTrialPeriod: recruiting.contract && recruiting.contract.endOfTrialPeriod ? new Date(recruiting.contract.endOfTrialPeriod) : undefined,
            candidateFollowupDate: recruiting.candidate.followupDate ? new Date(recruiting.candidate.followupDate) : undefined,
            status: props.abort ? ERecruitingStatus.STATUS_ABORT : (props.contract ? ERecruitingStatus.STATUS_CONTRACT : recruiting.status),
            source: recruiting.source,
            desiredSalary: recruiting.desiredSalary,
            contractSalary: recruiting.contract && recruiting.contract.contractSalary ? recruiting.contract.contractSalary : recruiting.desiredSalary,
            abortedBy: recruiting.abortedBy,
            declineReason: recruiting.declineReason,
            abortComment: recruiting.abortComment,
            comment: recruiting.comment,
            finishedTrialPeriod: recruiting.contract && recruiting.contract.finishedTrialPeriod ? recruiting.contract.finishedTrialPeriod : undefined,
            recommendationBy: recruiting.recommendationBy,
            projectPlanedEnd: recruiting.contract && recruiting.contract.projectPlanedEnd ? new Date(recruiting.contract.projectPlanedEnd) : undefined,
            projectEnd: recruiting.contract && recruiting.contract.projectEnd ? new Date(recruiting.contract.projectEnd) : undefined,
            projectCommissionPercent: recruiting.contract ? recruiting.contract.projectCommissionPercent : 0,
            projectValuedHoursPerMonth: recruiting.contract ? recruiting.contract.projectValuedHoursPerMonth : 0,
            projectInvoiceCreationDay: recruiting.contract ? recruiting.contract.projectInvoiceCreationDay : 0,
            projectInvoiceCreationInterval: recruiting.contract ? recruiting.contract.projectInvoiceCreationInterval : 0,
            contractComment: recruiting.contract ? recruiting.contract.comment : '',
            sharedDocumentIds: recruiting.sharedDocumentIds
        };
    }

    @autobind
    save() {
        const recruiting: IRecruitingForAPI = {
            id: this.props.recruiting.id,
            source: this.state.source,
            vacancyId: this.state.vacancy ? this.state.vacancy.id : 0,
            webVacancyId: this.state.webVacancy ? this.state.webVacancy.id : 0,
            url: this.state.url,
            partnerId: this.state.partner ? this.state.partner.id : 0,
            responsibleEmployeeId: this.state.employee.id,
            responsibleContactId: this.state.responsibleContact?.id,
            suggestedById: this.state.suggestedBy ? this.state.suggestedBy.id : 0,
            dateOfAbort: (this.state.dateOfAbort) ? moment(this.state.dateOfAbort).format("Y-MM-DD") : '',
            contractSigningDate: (this.state.contractSigningDate) ? moment(this.state.contractSigningDate).format("Y-MM-DD") : '',
            contractBeginningDate: (this.state.contractBeginningDate) ? moment(this.state.contractBeginningDate).format("Y-MM-DD") : '',
            endOfTrialPeriod: (this.state.endOfTrialPeriod) ? moment(this.state.endOfTrialPeriod).format("Y-MM-DD") : '',
            desiredSalary: this.state.desiredSalary,
            contractSalary: this.state.contractSalary,
            abortedBy: this.state.abortedBy,
            declineReason: this.state.declineReason,
            abortComment: this.state.abortComment,
            status: this.state.status,
            finishedTrialPeriod: this.state.finishedTrialPeriod,
            recommendationBy: this.state.recommendationBy,
            comment: this.state.comment,
            projectCommissionPercent: this.state.projectCommissionPercent,
            projectValuedHoursPerMonth: this.state.projectValuedHoursPerMonth,
            projectPlanedEnd: (this.state.projectPlanedEnd) ? moment(this.state.projectPlanedEnd).format("Y-MM-DD") : '',
            projectEnd: (this.state.projectEnd) ? moment(this.state.projectEnd).format("Y-MM-DD") : '',
            projectInvoiceCreationDay: this.state.projectInvoiceCreationDay,
            projectInvoiceCreationInterval: this.state.projectInvoiceCreationInterval,
            validateFreelancer: this.state.vacancy?.billingType === EBillingType.BILLING_TYPE_PROJECT,
            companyLocationId: this.state.companyLocation ? this.state.companyLocation.id : undefined,
            candidateFollowupDate: (this.state.candidateFollowupDate) ? moment(this.state.candidateFollowupDate).format("Y-MM-DD") : '',
            sharedDocumentIds: this.state.sharedDocumentIds,
            contractComment: this.state.contractComment
        }

        this.props.updateRecruitingAction(this.props.token, recruiting);
        return;
    }

    render() {
        const abortForm = <>
            <h4 style={{margin: 5}}>{translate('pages.recruiting.abortInformation')}</h4>
            <PaperRow>
                <CustomDatePicker
                    label={translate('pages.recruiting.dateOfAbort')}
                    value={this.state.dateOfAbort}
                    required
                    disableFuture={true}
                    onChange={(value) => this.setState({
                        dateOfAbort: value
                    })}
                    error={Boolean(this.props.validationErrors.dateOfAbort)}
                    helperText={this.props.validationErrors.dateOfAbort}
                />
                <RecruitingAbortedByDropDown
                    value={this.state.abortedBy}
                    onChange={(event) => this.setState({
                        abortedBy: event.target.value
                    })}
                    error={Boolean(this.props.validationErrors.abortedBy)}
                    helperText={this.props.validationErrors.abortedBy}
                />
            </PaperRow>
            <PaperRow>
                <RecruitingDeclineReasonDropDown
                    onChange={(reason) => this.setState({
                        declineReason: reason
                    })}
                    abortedBy={this.state.abortedBy}
                    value={this.state.declineReason}
                    error={Boolean(this.props.validationErrors.declineReason)}
                    helperText={this.props.validationErrors.declineReason}
                />
                <CustomDatePicker
                    label={'Wiedervorlage am für Kandidat erstellen (optional)'}
                    flex
                    value={this.state.candidateFollowupDate}
                    onChange={(value) => this.setState({
                        candidateFollowupDate: value
                    })}
                    error={Boolean(this.props.validationErrors.candidateFollowupDate)}
                    helperText={this.props.validationErrors.candidateFollowupDate}
                />
            </PaperRow>
            <PaperRow>
                <CustomTextField
                    multiline
                    required
                    label={translate('pages.recruiting.abortComment')}
                    value={this.state.abortComment}
                    onChange={(value) => this.setState({
                        abortComment: value
                    })}
                    error={Boolean(this.props.validationErrors.abortComment)}
                    helperText={this.props.validationErrors.abortComment}
                    rows={7}
                />
            </PaperRow>
        </>;

        const contractForm = <>
            <h4 style={{margin: 5}}>{translate('pages.recruiting.contractInformation')}</h4>
            {this.props.recruiting.type === ERecruitingType.TYPE_DEFAULT
                ?
                <>
                    <PaperRow>
                        <CustomDatePicker
                            flex
                            label={translate('pages.recruiting.contractSigningDate')}
                            value={this.state.contractSigningDate}
                            onChange={(value) => this.setState({
                                contractSigningDate: value
                            })}
                            error={Boolean(this.props.validationErrors.contractSigningDate)}
                            helperText={this.props.validationErrors.contractSigningDate}
                        />
                        <CustomDatePicker
                            flex
                            label={translate('pages.recruiting.contractBeginningDate')}
                            value={this.state.contractBeginningDate}
                            error={Boolean(this.props.validationErrors.contractBeginningDate)}
                            helperText={this.props.validationErrors.contractBeginningDate}
                            onChange={(value) => {
                                const endOfTrialPeriod = moment(value).add(6, 'months');

                                this.setState({
                                    contractBeginningDate: value,
                                    endOfTrialPeriod: momentToDate(endOfTrialPeriod)
                                });
                            }}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomDatePicker
                            flex
                            label={translate('pages.recruiting.endOfTrialPeriodDate')}
                            value={this.state.endOfTrialPeriod}
                            onChange={(value) => this.setState({
                                endOfTrialPeriod: value
                            })}
                        />
                        <LegacyYesNoDropDown
                            onChange={(event) => {
                                const value = event.target.value;
                                this.setState({
                                    finishedTrialPeriod: value === 1 ? true : value === 0 ? false : undefined
                                })
                            }}
                            value={this.state.finishedTrialPeriod}
                            label={translate('pages.recruiting.finishedTrialPeriod')}
                            error={Boolean(this.props.validationErrors.finishedTrialPeriod)}
                            helperText={this.props.validationErrors.finishedTrialPeriod}
                        />
                        <CustomTextField
                            label={translate('pages.recruiting.contractSalary')}
                            value={this.state.contractSalary}
                            onChange={(value) => this.setState({
                                contractSalary: value
                            })}
                            error={Boolean(this.props.validationErrors.contractSalary)}
                            helperText={this.props.validationErrors.contractSalary}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomTextField
                            multiline
                            required
                            label={'Kommentar zur Vermittlung'}
                            value={this.state.contractComment}
                            onChange={(value) => this.setState({
                                contractComment: value
                            })}
                            rows={7}
                        />
                    </PaperRow>
                </>
                :
                <>
                    <PaperRow>
                        <CustomDatePicker
                            label={translate('pages.recruiting.contractSigningDate')}
                            value={this.state.contractSigningDate}
                            onChange={(value) => this.setState({
                                contractSigningDate: value
                            })}
                            error={Boolean(this.props.validationErrors.contractSigningDate)}
                            helperText={this.props.validationErrors.contractSigningDate}
                        />
                        <CustomDatePicker
                            label={translate('pages.recruiting.contractBeginningDate')}
                            value={this.state.contractBeginningDate}
                            error={Boolean(this.props.validationErrors.contractBeginningDate)}
                            helperText={this.props.validationErrors.contractBeginningDate}
                            onChange={(value) => {
                                const endOfTrialPeriod = moment(value).add(6, 'months');

                                this.setState({
                                    contractBeginningDate: value,
                                    endOfTrialPeriod: momentToDate(endOfTrialPeriod)
                                });
                            }}
                        />
                        <CustomDatePicker
                            label={translate('pages.recruiting.projectPlanedEnd')}
                            value={this.state.projectPlanedEnd}
                            onChange={(value) => this.setState({
                                projectPlanedEnd: value
                            })}
                            error={Boolean(this.props.validationErrors.projectPlanedEnd)}
                            helperText={this.props.validationErrors.projectPlanedEnd}
                        />
                        <CustomDatePicker
                            label={translate('pages.recruiting.projectEnd')}
                            value={this.state.projectEnd}
                            onChange={(value) => this.setState({
                                projectEnd: value
                            })}
                        />
                    </PaperRow>
                    <PaperRow>
                        <CustomTextField
                            label={translate('pages.recruiting.contractSalaryFreelancer')}
                            value={this.state.contractSalary}
                            onChange={(value) => this.setState({
                                contractSalary: value
                            })}
                            error={Boolean(this.props.validationErrors.contractSalary)}
                            helperText={this.props.validationErrors.contractSalary}
                        />
                        <CustomTextField
                            label={translate('pages.recruiting.projectCommissionPercent')}
                            value={this.state.projectCommissionPercent}
                            onChange={(value) => this.setState({
                                projectCommissionPercent: value
                            })}
                            error={Boolean(this.props.validationErrors.projectCommissionPercent)}
                            helperText={this.props.validationErrors.projectCommissionPercent}
                        />
                        <CustomTextField
                            label={translate('pages.recruiting.projectValuedHoursPerMonth')}
                            value={this.state.projectValuedHoursPerMonth}
                            onChange={(value) => this.setState({
                                projectValuedHoursPerMonth: value
                            })}
                            error={Boolean(this.props.validationErrors.projectValuedHoursPerMonth)}
                            helperText={this.props.validationErrors.projectValuedHoursPerMonth}
                        />
                        <NumberDropDown
                            onChange={(event) => this.setState({
                                projectInvoiceCreationDay: event.target.value
                            })}
                            value={this.state.projectInvoiceCreationDay}
                            stepSize={1}
                            label={translate('pages.recruiting.projectInvoiceCreationDay')}
                            max={31}
                            error={Boolean(this.props.validationErrors.projectInvoiceCreationDay)}
                            helperText={this.props.validationErrors.projectInvoiceCreationDay}
                        />
                        <NumberDropDown
                            onChange={(event) => this.setState({
                                projectInvoiceCreationInterval: event.target.value
                            })}
                            value={this.state.projectInvoiceCreationInterval}
                            stepSize={1}
                            label={translate('pages.recruiting.projectInvoiceCreationInterval')}
                            max={3}
                            error={Boolean(this.props.validationErrors.projectInvoiceCreationInterval)}
                            helperText={this.props.validationErrors.projectInvoiceCreationInterval}
                        />
                    </PaperRow>
                </>
            }
        </>;

        if (this.props.abort) {
            return <>
                <div style={{ width: "100%", overflow: "auto" }}>
                    {abortForm}
                </div>
                <CustomDialogActions
                    buttons={[{
                        void: () => {
                            this.props.resetFormValidation();
                            this.save();
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
            </>
        }

        if (this.props.contract) {
            return <>
                <div style={{ width: "100%", overflow: "auto" }}>
                    {contractForm}
                </div>
                <CustomDialogActions
                    buttons={[{
                        void: () => {
                            this.props.resetFormValidation();
                            this.save();
                        },
                        label: translate('misc.buttons.save')
                    }]}
                />
            </>
        }

        return <>
            <CustomValidationErrors translationKey={'pages.recruiting'}/>
            <div style={{ width: "100%", overflow: "auto" }}>
                {
                    this.state.status === ERecruitingStatus.STATUS_CONTRACT && this.state.vacancy && this.state.vacancy.count === 1 && this.state.status !== this.props.recruiting.status &&
                    <div style={{color: COLOR_YELLOW}}>&nbsp;&nbsp;{translate('pages.recruiting.closingHint')}</div>
                }
                <h4 style={{margin: 5}}>Stammdaten</h4>
                <PaperRow>
                    { this.state.status === ERecruitingStatus.STATUS_FINISH || this.state.status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD
                        ?
                        <CustomTextField
                            disabled={true}
                            label={translate('pages.recruiting.status')}
                            value={translate('pages.recruiting.statusValues.' + this.state.status)}
                        />
                        :
                        <RecruitingStatusDropDown
                            required
                            value={this.state.status}
                            onChange={(event) => {
                                if (event.target.value === ERecruitingStatus.STATUS_ABORT) {
                                    this.setState({
                                        status: event.target.value,
                                        dateOfAbort: new Date()
                                    });
                                    return;
                                }
                                this.setState({
                                    status: event.target.value
                                })
                            }}
                        />
                    }
                    <CandidateSourceDropDown
                        required
                        value={this.state.source}
                        onChange={(event) => {
                            this.setState({
                                source: event.target.value
                            });
                        }}
                        error={Boolean(this.props.validationErrors.source)}
                        helperText={this.props.validationErrors.source}
                    />
                </PaperRow>
                <PaperRow>
                    <EmployeeAutocomplete
                        label={translate('misc.responsibleEmployee')}
                        value={this.state.employee}
                        required={true}
                        onChange={(value) => this.setState({
                            employee: value
                        })}
                    />
                    <EmployeeAutocomplete
                        label={translate('pages.vacancy.suggestedByEmployeeFormLabel')}
                        value={this.state.suggestedBy}
                        required={true}
                        onChange={(value) => this.setState({
                            suggestedBy: value
                        })}
                    />
                    <CompanyContactAutocompleteDropDown
                        onChange={(event, value: ICompanyContactPartial) => this.setState({
                            responsibleContact: value
                        })}
                        onAdd={() => {
                            this.props.setUiCompanyContactAddDataAction({
                                companyId: this.props.recruiting.company.id
                            });
                        }}
                        value={this.state.responsibleContact}
                        companyId={this.props.recruiting.company.id}
                    />
                </PaperRow>
                {(this.state.company.id === 233) && <PaperRow>
                    <RecruitingSharedDocumentAutocomplete
                        initialDocumentIds={this.state.sharedDocumentIds}
                        setSelectedDocuments={(documents) => this.setState({sharedDocumentIds: documents.map((document) => document.id)})}
                        candidateId={this.props.recruiting.candidate.id}
                    />
                </PaperRow>
                }
                { this.state.status === 'abort' &&
                <>
                    <Divider/>
                    {abortForm}
                </>
                }
                { (this.state.status === ERecruitingStatus.STATUS_CONTRACT || this.state.status === ERecruitingStatus.STATUS_FINISH || this.state.status === ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD) &&
                <>
                    <Divider/>
                    {contractForm}
                </>
                }
                <Divider/>
                <PaperRow>
                    <div className={"flex1"}>
                        <CustomRadioGroupInput
                            value={this.state.suggestedFor}
                            label={<h4 style={{margin: 5}}>Vorgeschlagen</h4>}
                            onChange={(suggestedFor) => {
                                if (!suggestedFor) {
                                    return;
                                }
                                //@ts-ignore
                                this.setState({suggestedFor})
                            }}
                            states={[{
                                value: 'vacancy',
                                label: 'bei Vakanz'
                            }, {
                                value: 'webVacancy',
                                label: 'für Stelle'
                            }, {
                                value: 'initiative',
                                label: 'initiativ'
                            }]}
                        />
                    </div>
                    <CustomTextField
                        label={translate('pages.recruiting.suggestedWithSalary')}
                        value={this.state.desiredSalary}
                        onChange={(value) => this.setState({
                            desiredSalary: parseFloat(value)
                        })}
                    />
                </PaperRow>
                <PaperRow>
                    {this.state.suggestedFor === 'vacancy' &&
                        <>
                            <CompanyAutocomplete
                                required
                                value={this.state.company || null}
                                error={Boolean(this.props.validationErrors.company)}
                                helperText={this.props.validationErrors.company}
                                onChange={(company) => {
                                    if (!company) {
                                        return;
                                    }

                                    this.setState({
                                        company
                                    })
                                }}
                            />
                            <VacancyAutocomplete
                                label={translate('misc.vacancy')}
                                value={this.state.vacancy || null}
                                onChange={(vacancy) => this.setState({
                                    vacancy
                                })}
                                companyId={this.state.company.id}
                                error={Boolean(this.props.validationErrors.vacancyId)}
                                helperText={this.props.validationErrors.vacancyId}
                            />
                        </>
                    }
                    {this.state.suggestedFor === 'webVacancy' &&
                        <>
                            <CompanyAutocomplete
                                required
                                value={this.state.company || null}
                                error={Boolean(this.props.validationErrors.company)}
                                helperText={this.props.validationErrors.company}
                                onChange={(company) => {
                                    if (!company) {
                                        return;
                                    }

                                    this.setState({
                                        company
                                    })
                                }}
                            />
                            <WebVacancyAutocomplete
                                value={this.state.webVacancy || null}
                                companyId={this.props.recruiting.company.id}
                                error={Boolean(this.props.validationErrors.webVacancy)}
                                helperText={this.props.validationErrors.webVacancy}
                                onChange={(value) => this.setState({
                                    //@ts-ignore
                                    webVacancy: value ? (value as IWebVacancyPartial) : undefined
                                })}
                            />
                            <CompanyLocationAutocompleteDropDown
                                onChange={(event, value) => this.setState({
                                    companyLocation: value || undefined
                                })}
                                value={this.state.companyLocation}
                                companyId={this.props.recruiting.company.id}
                                filterByCompany={true}
                            />
                        </>
                    }
                    {this.state.suggestedFor === 'initiative' &&
                    <>
                        <PaperRow>
                            <CompanyAutocomplete
                                required
                                value={this.state.company || null}
                                error={Boolean(this.props.validationErrors.company)}
                                helperText={this.props.validationErrors.company}
                                onChange={(company) => {
                                    if (!company) {
                                        return;
                                    }

                                    this.setState({
                                        company
                                    })
                                }}
                            />
                            <CompanyLocationAutocompleteDropDown
                                onChange={(event, value) => this.setState({
                                    companyLocation: value || undefined
                                })}
                                value={this.state.companyLocation}
                                companyId={this.props.recruiting.company.id}
                                filterByCompany={true}
                            />
                        </PaperRow>
                    </>
                    }
                </PaperRow>
                {this.state.suggestedFor === 'initiative' &&
                    <>
                        <PaperRow>
                            <CustomUrlField
                                flex={2}
                                url={this.state.url}
                                label={'URL zur Stelle'}
                                value={this.state.url}
                                onChange={(url) => this.setState({url})}
                            />
                        </PaperRow>
                    </>
                }
                <PaperRow>
                    <CustomTextField
                        multiline
                        label={translate('misc.furtherInformation')}
                        value={this.state.comment}
                        onChange={(value) => this.setState({
                            comment: value
                        })}
                        rows={8}
                    />
                </PaperRow>
                <PaperRow>
                    <LegacyCompanyAutocompleteDropDown
                        onChange={(event, value: ICompany) => this.setState({
                            partner: value
                        })}
                        label={translate('pages.recruiting.partner')}
                        value={this.state.partner}
                    />
                    <CustomTextField
                        multiline
                        label={translate('pages.recruiting.recommendationBy')}
                        value={this.state.recommendationBy}
                        onChange={(value) => this.setState({
                            recommendationBy: value
                        })}
                    />
                </PaperRow>
                {
                    this.props.userIsAdmin &&
                    <PaperRow>
                        <BillingConfigurationElement
                            billingSetting={this.props.recruiting}
                            entity={EEntities.Recruiting}
                            entityId={this.props.recruiting.id}
                        />
                    </PaperRow>
                }
            </div>
            <CustomDialogActions
                buttons={[{
                    void: () => this.props.setRecruitingPageRecruitingToDeleteAction(this.props.recruiting.id),
                    label: 'Löschen'
                }, {
                    void: () => this.props.setRecruitingPageCreateCongratulationMailAction(this.props.recruiting.id),
                    label: translate('pages.recruiting.createCongratulationMail'),
                    hidden: this.props.recruiting.congratulationMailSend !== EMailSendStatus.open || this.props.recruiting.status !== ERecruitingStatus.STATUS_CONTRACT,
                }, {
                    void: () => {
                        this.props.resetFormValidation();
                        this.save();
                    },
                    label: translate('misc.buttons.save')
                }]}
            />
        </>;
    }
}

const mapState = (store: IStore) => ({
    token: getToken(store),
    validationErrors: getValidationErrorsFromStore(store),
    userIsAdmin: userHasRole2(ROLE_ADMIN, store)
});

const mapDispatch = {
    updateRecruitingAction,
    setUiCompanyContactAddDataAction,
    registerError,
    setRecruitingPageRecruitingToDeleteAction,
    setUiCompanyLocationAddDataAction,
    resetFormValidation,
    setRecruitingPageCreateCongratulationMailAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(RecruitingEditForm);
