import React, { PropsWithChildren } from 'react';
import { Button } from '@material-ui/core';
import { darker } from '../../theme/theme';

interface IProps extends PropsWithChildren<any> {
    buttonConfig?: {
        text: string;
        action: () => void;
    }
    maxWidth?: number;
}

const CustomInfo: React.FC<IProps> = (props: IProps) => {
    return <div
        style={{borderRadius: 5, background: '#92AECF', color: darker, maxWidth: props.maxWidth ? props.maxWidth : 'unset'}}
        className={"flexContainerColumn flexAlignStart gap5 padding5"}
    >
        {props.children}
        {props.buttonConfig &&
            <Button variant={"contained"} color="secondary" size="small" onClick={props.buttonConfig?.action}>
                {props.buttonConfig?.text}
            </Button>
        }
    </div>
}

export default CustomInfo;
