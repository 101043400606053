import React from 'react';
import { IInvoiceFormState } from '../../../../definitions/billingPage.definitions';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { setBillingPageInvoiceFormAction } from '../../../../redux/billingPage/billingPage.actions';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import moment from 'moment';
import { momentToDate } from '../../../../utils/date.utils';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';

interface IProps {
    formState: IInvoiceFormState;
}

const InvoicePaymentRemindView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const formState = props.formState;
    const validationErrors = useSelector(getValidationErrorsFromStore);

    return (
        <>
            <PaperRow>
                <CustomCheckboxInput
                    checked={props.formState.remindOfPaymentCreated}
                    label={"Zahlungserinnerung verschickt"}
                    onChange={() => {
                        const dueDate = moment().add(7, 'days');

                        dispatch(setBillingPageInvoiceFormAction({
                            remindOfPaymentCreated: !props.formState.remindOfPaymentCreated,
                            remindOfPaymentDate: momentToDate(moment()),
                            remindOfPaymentDueDate: momentToDate(dueDate)
                        }))
                    }}
                />
                {props.formState.remindOfPaymentCreated &&
                    <>
                        <CustomDatePicker
                            label={'Datum der Zahlungserinnerung'}
                            value={formState.remindOfPaymentDate}
                            onChange={(value) => {
                                const dueDate = moment(value).add(7, 'days');

                                dispatch(setBillingPageInvoiceFormAction({
                                    remindOfPaymentDate: value,
                                    remindOfPaymentDueDate: momentToDate(dueDate)
                                }))
                            }}
                            error={Boolean(validationErrors.date)}
                            helperText={validationErrors.date}
                        />
                        <CustomDatePicker
                            label={'Fälligkeitsdatum der Zahlungserinnerung'}
                            value={formState.remindOfPaymentDueDate}
                            onChange={(value) =>
                                dispatch(setBillingPageInvoiceFormAction({
                                    remindOfPaymentDueDate: value
                                }))
                            }
                            error={Boolean(validationErrors.dueDate)}
                            helperText={validationErrors.dueDate}
                        />
                    </>
                }
            </PaperRow>
        </>
    );
}

export default InvoicePaymentRemindView;
