import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EEntities, ICandidatePartial, IInvoice, IRecruitingPartial } from '../../../definitions/entities.definition';
import { getDefaultCompanyColumn } from '../../../utils/components.utils';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { translate } from '../../../translation/translate.utils';
import { getToken } from '../../../selectors/app.selectors';
import {
    setBillingPageAddPaymentForInvoiceAction,
    setBillingPageInvoiceListSortingOptionAction,
    setBillingPageInvoiceToDeleteAction,
} from '../../../redux/billingPage/billingPage.actions';
import { EDocumentStatus, EInvoiceType } from '../../../definitions/billingPage.definitions';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import RecruitingCellRenderer from '../../../components/CustomCellRenderer/RecruitingCellRenderer';
import InvoiceTypeCellRenderer from '../../../components/CustomCellRenderer/InvoiceTypeCellRenderer';
import DocumentStatusCellRenderer from '../../../components/CustomCalendar/DocumentStatusCellRenderer';
import { EIcons } from '../../../components/Icons/IconFactory';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import { API_URL } from '../../../requests/routes';
import { Tooltip } from '@material-ui/core';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';

interface IProps {
    loading: boolean;
    invoices: IInvoice[];
    scrollCallback?: () => void;
    onReload: () => void;
    totalRecords?: number;
    hideStatusColumn?: boolean;
    disableOrder?: boolean;
    sum?: number;
}

const InvoiceList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const invoices = props.invoices;
    const scrollCallback = props.scrollCallback;
    const loading = props.loading;
    const totalRecords = props.totalRecords;
    const onReload = props.onReload;
    const hideStatusColumn = props.hideStatusColumn || false;
    const disableOrder = props.disableOrder || false;
    const sum = props.sum;

    const columns: ICustomTableColumnConfig = {
        number: {
            header: translate('pages.billing.invoiceNumber'),
            property: "number",
            width: 160,
            disableOrder
        },
        status: {
            header: translate('misc.status'),
            property: "status",
            width: 120,
            cellRenderer: (status: EDocumentStatus) => {
                return <DocumentStatusCellRenderer status={status} />
            },
            disableOrder
        },
        invoiceType: {
            header: 'Typ',
            property: 'invoiceType',
            width: 60,
            align: ECellAlign.center,
            cellRenderer: (invoiceType: EInvoiceType) => {
                return <InvoiceTypeCellRenderer invoiceType={invoiceType} />
            },
            disableOrder
        },
        date: {
            header: translate('misc.date'),
            property: "date",
            width: 100,
            cellRenderer: (dateTime: string) => <DateCellRenderer date={dateTime} />,
            disableOrder
        },
        dueDate: {
            header: translate('misc.dueDate'),
            property: "dueDate",
            width: 100,
            cellRenderer: (dateTime: string, entity) => {
                const invoice = entity as IInvoice;
                if (invoice.status === EDocumentStatus.closed) {
                    return <DateCellRenderer date={dateTime} />
                }
                if (invoice.dunningLetters.length > 0) {
                    return <div className={"flexContainerColumn"}>
                        <DateCellRenderer markOverdue date={invoice.dunningLetters[0].dueDate} />
                        <Tooltip title={"Ursprüngliches Fälligkeits-Datum"}>
                            <div className={"formContent"}><DateCellRenderer date={dateTime} /></div>
                        </Tooltip>
                    </div>
                }
                if (invoice.remindOfPaymentDueDate) {
                    return <div className={"flexContainerColumn"}>
                        <DateCellRenderer markOverdue date={invoice.remindOfPaymentDueDate} />
                        <Tooltip title={"Ursprüngliches Fälligkeits-Datum"}>
                            <div className={"formContent"}><DateCellRenderer date={dateTime} /></div>
                        </Tooltip>
                    </div>
                }
                return <DateCellRenderer markOverdue date={dateTime} />
            },
            disableOrder
        },
        company: getDefaultCompanyColumn(),
        candidate: {
            header: translate('pages.activity.properties.candidate'),
            disableOrder: true,
            property: 'recruiting.candidate',
            cellRenderer: (candidate: ICandidatePartial) => {
                if (candidate) {
                    return <CandidateCellRenderer candidate={candidate} />
                }
            }
        },
        recruiting: {
            width: 150,
            disableOrder: true,
            header: 'Prozess',
            property: 'recruiting',
            cellRenderer: (recruiting: IRecruitingPartial) => {
                if (recruiting) {
                    return <RecruitingCellRenderer recruiting={recruiting} />
                }
            }
        },
        totalNet: {
            header: translate('pages.billing.totalNet'),
            property: "totalNet",
            align: ECellAlign.right,
            width: 120,
            cellRenderer: (value: number) => <CurrencyCellRenderer value={value} />,
            disableOrder
        },
    };

    if (hideStatusColumn) {
        delete columns.status;
    }

    return (
        <CustomTable
            config={{
                entity: EEntities.Invoice,
                columnConfig: columns,
                sortedEntries: invoices,
                loading,
                scrollCallback,
                onRowDoubleClick: (invoiceId: number) => dispatch(setUiEditView(EEntityView.invoice, invoiceId)),
                listActionButtons: [{
                    action: (invoiceId: number, entity) => {
                        const invoice = entity as IInvoice;
                        window.open(`${API_URL}/invoice/${invoice.id}/print?Authorization=${token}`)
                    },
                    translationKey: "pages.billing.printDocument",
                    icon: EIcons.Print,
                }, {
                    action: (invoiceId: number) => dispatch(setBillingPageAddPaymentForInvoiceAction(invoiceId)),
                    translationKey: "pages.billing.addPayment",
                    icon: EIcons.Money,
                }, {
                    action: (invoiceId: number) => dispatch(setBillingPageInvoiceToDeleteAction(invoiceId)),
                    translationKey: "tables.openDeleteView",
                    icon: EIcons.Delete,
                }],
                tableHeaderCallbacks: {
                    setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                        dispatch(setBillingPageInvoiceListSortingOptionAction(propertyToSort, sortOption)),
                },
                onReload,
                footerText: sum ? 'Summe: ' + sum.toLocaleString("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: 2 }) : undefined,
                count: invoices.length,
                total: totalRecords,
            }}
        />
    );
}

export default InvoiceList;
