import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getNotifyUnreadCountAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { setInterval } from 'timers';
import { ClientApi } from '../../requests/ClientApi';
import { getNotificationsRouteConfig, putNotificationReadRouteConfig } from '../../requests/routes';
import { resetUiNotificationsCountAction, setUiEditView } from '../../redux/ui/ui.actions';
import {
    ENotification,
    INotification,
    IRecruitingPartial,
    IWebVacancyPartial,
} from '../../definitions/entities.definition';
import { celebrationAnimation } from '../../utils/celebrationAnimation.utils';
import { registerSuccess } from '../../redux/error/error.actions';
import { Button, Slide, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { EEntityView } from '../../definitions/ui.definitions';

interface IConfirmationMessage {
    notificationId: number;
    text: string;
    buttons: {
        icon: EIcons,
        label: string,
        action: () => void
    }[]
}

type TState = {
    loading: boolean;
    confirmation?: IConfirmationMessage
}

class NotificationController extends React.PureComponent<PropsFromRedux, TState> {
    constructor(props: PropsFromRedux) {
        super(props);

        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        setInterval(this.tick, 600000);
        this.tick();

        document.addEventListener('mousemove', (e) => {
            if (this.props.countNotifications > 0 && !this.state.loading) {
                this.setState({loading: true});
                ClientApi.request(getNotificationsRouteConfig, {token: this.props.token}).then((result: INotification[]) => {
                    this.setState({loading: false});
                    this.props.resetUiNotificationsCountAction();

                    const notification = result[0];

                    if (notification.enum === ENotification.NOTIFICATION_CONTRACT) {
                        celebrationAnimation((notification.recruiting as IRecruitingPartial).responsibleEmployee.id, notification.id, this.props.token);
                        this.props.registerSuccess(notification.text);
                    }

                    if (notification.enum === ENotification.NOTIFICATION_WEB_VACANCY_OFFLINE) {
                        const webVacancy = notification.webVacancy as IWebVacancyPartial;
                        this.setState({
                            confirmation: {
                                notificationId: notification.id,
                                text: notification.text,
                                buttons: [{
                                    action: () => this.props.setUiEditView(EEntityView.vacancy, webVacancy.vacancies[0].id),
                                    label: 'Vakanz öffnen',
                                    icon: EIcons.Edit
                                }]
                            }
                        });
                    }
                });
            }
        });
    }

    tick = () => {
        this.props.getNotifyUnreadCountAction(this.props.token);
    };

    markAsRead = (notificationId: number) => {
        ClientApi.request(putNotificationReadRouteConfig, {token: this.props.token, id: notificationId}).then(() => {
            this.setState({confirmation: undefined});
            this.tick()
        })
    }

    render() {
        if (!this.state.confirmation) {
            return null;
        }

        return <>
            <Snackbar
                open={true}
                TransitionComponent={(props) => <Slide {...props} direction="right" />}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <MuiAlert elevation={6} variant="filled" severity={"info"}>
                    <div className={"flexContainerColumn"} >
                        {this.state.confirmation.text}
                        <div className={"flexContainerRow gap5 marginTop5 alignHorizontalRight"}>
                            {this.state.confirmation.buttons.map((button) =>
                                <Button
                                    key={button.label}
                                    variant={"outlined"}
                                    color="inherit"
                                    size="small"
                                    startIcon={IconFactory.getIconComponent(button.icon, {color: 'white'})}
                                    onClick={button.action}
                                >
                                    {button.label}
                                </Button>
                            )}
                            <Button
                                variant={"outlined"}
                                color="inherit"
                                size="small"
                                startIcon={IconFactory.getIconComponent(EIcons.Close, {color: 'white'})}
                                onClick={() => this.markAsRead((this.state.confirmation as IConfirmationMessage).notificationId)}
                            >
                                ausblenden
                            </Button>
                        </div>
                    </div>
                </MuiAlert>
            </Snackbar>
        </>;
    }
}

const mapState = (store: IStore) => ({
    token: getToken(store),
    countNotifications: store.ui.countNotifications
});

const mapDispatch = {
    registerSuccess,
    getNotifyUnreadCountAction,
    resetUiNotificationsCountAction,
    setUiEditView
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(NotificationController);
