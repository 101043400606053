import React from 'react';
import { EEntities, ICandidateExperience } from '../../../../../definitions/entities.definition';
import { themeGreenLight } from '../../../../../theme/theme';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

interface IProps {
    experience: ICandidateExperience[];
}

const CandidateExperienceList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    color: themeGreenLight.palette.primary.main,
                    entity: EEntities.CandidateProfession,
                    columnConfig: {
                        date: {
                            header: 'Zeitraum',
                            property: "fromYear",
                            width: 140,
                            cellRenderer: (created: string, entity) => {
                                const entityCast = entity as ICandidateExperience;

                                const toString = entityCast.untilToday ? ' bis heute' : entityCast.toMonth ? ' bis ' + entityCast.toMonth.toString().padStart(2, '0') + '/' + entityCast.toYear : '';

                                return entityCast.fromMonth ? entityCast.fromMonth.toString().padStart(2, '0') + '/' + entityCast.fromYear + toString :  entityCast.fromYear + toString;
                            },
                        },
                        title: {
                            header: "Stellenbeschreibung",
                            property: "title",
                            width: 300
                        },
                        company: {
                            header: "Firma",
                            property: "company",
                            flex: 1,
                        },
                        city: {
                            header: "Stadt",
                            property: "city",
                            flex: 1,
                        },
                        skillCount: {
                            header: "Skills",
                            width: 50,
                            property: "skillCount",
                        }
                    },
                    sortedEntries: props.experience,
                }}
            />
        </>
    );
};

export default CandidateExperienceList;
