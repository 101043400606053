import React from 'react';
import { IBattery } from '../../../definitions/entities.definition';
import { Tooltip } from '@material-ui/core';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';

interface IProps {
    battery: IBattery;
    size?: 'small'|'medium';
}

const Battery: React.FC<IProps> = (props) => {
    const battery = props.battery;
    const width = (battery.percentValue <= 100 ? battery.percentValue : 100) + '%';
    const size = props.size || 'small';
    const batteryWidth = size === 'small' ? 28 : 80;
    const className = 'battery-color-' + battery.status;

    return <>
        <Tooltip title={<>
            <table>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-empty"}>&nbsp;</div> Geringer Umsatz</td>
                    <td></td>
                </tr>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-nearToMin"}>&nbsp;</div> Fast Mindest-Ziel</td>
                    <td>{((battery.minTarget/100*90) / 1000).toFixed(1)} k€</td>
                </tr>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-min"}>&nbsp;</div> Mindest-Ziel</td>
                    <td>{(battery.minTarget / 1000).toFixed(1)} k€</td>
                </tr>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-nearToTop"}>&nbsp;</div> Fast Top-Ziel</td>
                    <td>{((battery.topTarget/100*85) / 1000).toFixed(1)} k€</td>
                </tr>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-top"}>&nbsp;</div> Top-Ziel</td>
                    <td>{(battery.topTarget / 1000).toFixed(1)} k€</td>
                </tr>
                <tr>
                    <td></td>
                    <td><div className={"dot battery-color-overTop"}>&nbsp;</div> Top-Ziel übertroffen</td>
                    <td>{(battery.topTarget * 1.2 / 1000).toFixed(1)} k€</td>
                </tr>
                <tr><td colSpan={3}><hr/></td></tr>
                <tr>
                    <td></td>
                    <td><div className={"dot " + className}>&nbsp;</div> Ist
                    </td>
                    <td>
                        <CurrencyCellRenderer roundedToThousand value={battery.value}/>
                    </td>
                </tr>
            </table>
        </>}>
            <div className={"battery " + (battery.percentValue === 0 ? 'empty' : '')} style={{width: batteryWidth}}>
                <div className={"battery-level " + (battery.percentValue === 0 ? 'empty ' : '') + className } style={{width}}>
                </div>
                {size === 'medium' &&
                    <span style={{fontSize: 10, position: 'relative', top: -2}}>
                        {(battery.value / 1000).toFixed(1)}
                        &nbsp;/&nbsp;
                        {(battery.topTarget / 1000).toFixed(1)}
                    </span>
                }
            </div>
        </Tooltip>
    </>;
}

export default Battery;
