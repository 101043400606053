import { MenuItem } from '@material-ui/core';
import React from 'react';
import { TMouseEventFunction } from '../../../definitions/redux/actions.definition';
import { translate } from '../../../translation/translate.utils';
import CustomDropDownInput from './CustomDropDownInput';
import { ERecruitingStatus } from '../../../definitions/entities.definition';

interface IProps {
    onChange: TMouseEventFunction;
    value?: ERecruitingStatus;
    showFinished?: boolean;
    showAbortTrial?: boolean;
    values?: ERecruitingStatus[];
    label?: string;
    emptyValue?: string;
    required?: boolean;
}

const RecruitingStatusDropDown: React.FC<IProps> = (props) => {
    const required = props.required || false;
    const statusValues: ERecruitingStatus[] = [
        ERecruitingStatus.STATUS_SUGGEST,
        ERecruitingStatus.STATUS_INTERVIEW,
        ERecruitingStatus.STATUS_CONTRACT,
        ERecruitingStatus.STATUS_ABORT
    ];

    if (props.showAbortTrial) {
        statusValues.push(ERecruitingStatus.STATUS_FAILED_TRIAL_PERIOD);
    }

    statusValues.push(ERecruitingStatus.STATUS_FINISH);

    let menuItems = (props.values ? props.values : statusValues).map((status: ERecruitingStatus, index: number) => (
        <MenuItem key={index + 1} value={status} disabled={status === ERecruitingStatus.STATUS_FINISH}>
            {translate('pages.recruiting.statusValues.' + status)}
        </MenuItem>
    ));

    if (!required) {
        menuItems = [
            <MenuItem key={0} value={0}>
                {props.emptyValue || translate('misc.choose')}
            </MenuItem>,
            ...menuItems,
        ];
    }

    return (
        <CustomDropDownInput
            onChange={props.onChange}
            value={props.value || 0}
            required={required}
            id={"recruiting-status-dropdown"}
            label={props.label || translate('pages.recruiting.status')}
        >
            {menuItems}
        </CustomDropDownInput>
    );
};

export default RecruitingStatusDropDown;
