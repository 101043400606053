import React from 'react';
import { Drawer } from '@material-ui/core';
import { useDrawerStyles } from './Drawer.styles';
import { EEntityView, IEditViewState } from '../../../definitions/ui.definitions';
import VacancyView from '../../EditViews/Vacancy/VacancyView';
import WebVacancyView from '../../EditViews/WebVacancy/WebVacancyView';
import { useSelector } from 'react-redux';
import CandidateView from '../../EditViews/Candidate/CandidateView';
import RecruitingView from '../../EditViews/Recruiting/RecruitingView';
import CompanyView from '../../EditViews/Company/CompanyView';
import EntityViewTitleBar from '../TopBar/EntityViewTitleBar';
import EntityViewActionsBar from '../TopBar/EntityViewActionsBar';
import { IStore } from '../../../definitions/redux/store.definitions';
import SuggestionView from '../../EditViews/Suggestion/SuggestionView';
import CompanyContactView from '../../EditViews/CompanyContact/CompanyContactView';
import InvoiceView from '../../EditViews/Invoice/InvoiceView';

interface IProps {
    editView: IEditViewState;
    open: boolean;
}

const RightDrawerEntityView: React.FC<IProps> = (props) => {
    const drawerClasses = useDrawerStyles();
    const editView = props.editView;
    const view = editView.view;
    const id = editView.entityId;
    const initialTab = editView.initialTab;

    const searchCandidateViewOpen = useSelector((store: IStore) => Boolean(store.ui.searchForVacancyId > 0 || store.ui.searchForWebVacancyId > 0));
    const bringToTop = (view === EEntityView.candidate) && searchCandidateViewOpen;

    const getEntityView = (view: EEntityView, id: number, initialTab?: number) => {
        switch (view) {
            case EEntityView.invoice:
                return <InvoiceView id={id} />;
            case EEntityView.vacancy:
                return <VacancyView id={id} />;
            case EEntityView.suggestion:
                return <SuggestionView id={id} initialTab={initialTab}/>;
            case EEntityView.webVacancy:
                return <WebVacancyView id={id} />;
            case EEntityView.candidate:
                return <CandidateView id={id}/>;
            case EEntityView.recruiting:
                return <RecruitingView id={id} />;
            case EEntityView.company:
                return <CompanyView id={id} />;
            case EEntityView.companyContact:
                return <CompanyContactView id={id} />;
        }

        return null;
    };

    let classes = drawerClasses.drawerOpen + ' ' + drawerClasses.entityWidth + ' ' + drawerClasses.boxShadow;
    if (bringToTop) {
        classes += ' ' + drawerClasses.drawerBringToTop;
    }

    return <>
        <Drawer
            id={props.open ? "entity-edit-view-drawer" : undefined}
            anchor={"right"}
            className={bringToTop ? "closeMeBeforeModal": ""}
            variant={"persistent"}
            classes={{paper: classes}}
            open={true}
            PaperProps={{ style: { width: props.open ? undefined : 1 }}}
        >
            <div className={'flexAutoGrow flexContainerRow marginTop5 marginBottom5'}>
                <EntityViewTitleBar editView={editView}/>
                <div className={'flex1 flexContainerRow flexRight marginTop5'}>
                    <EntityViewActionsBar editView={editView}/>
                </div>
            </div>
            <div style={{ display:"flex", marginTop:-3, flexDirection: "column", height: "calc(100% - 70px)", width: "100%"}}>
                {getEntityView(view, id, initialTab)}
            </div>
        </Drawer>
    </>;
}

export default RightDrawerEntityView;
