import {
    IGetInvoiceEvaluationDataAction,
    IGetInvoiceListAction,
    IPostInvoiceAction,
    IPutInvoiceAction,
    ISetBillingPageActiveTabAction,
    ISetBillingPageAddInvoiceDialogAction,
    ISetBillingPageAddInvoiceItemAction,
    ISetBillingPageAddPaymentForInvoiceAction,
    ISetBillingPageDeleteInvoiceItemAction,
    ISetBillingPageInvoiceFormAction,
    ISetBillingPageInvoiceGenerationDialogAction,
    ISetBillingPageInvoiceListFastSearchAction,
    ISetBillingPageInvoiceListFilterAction,
    ISetBillingPageInvoiceListFilterMenuAction,
    ISetBillingPageInvoiceListSortingOptionAction,
    ISetBillingPageInvoiceToDeleteAction,
    ISetBillingPageReloadInvoiceListAction,
    ISetBillingPageUpdateInvoiceItemAction,
    TReducerAction,
} from '../../definitions/redux/actions.definition';
import {
    IBillingPageState,
    IInvoiceItemForm,
    initialInvoiceFormState,
    preloadedStateBillingPage,
} from '../../definitions/billingPage.definitions';
import {
    BILLING_PAGE_ADD_INVOICE_ITEM,
    BILLING_PAGE_CALCULATE_INVOICE_SUM_FOOTER,
    BILLING_PAGE_DELETE_INVOICE_ITEM,
    BILLING_PAGE_SET_ACTIVE_TAB,
    BILLING_PAGE_SET_ADD_INVOICE_DIALOG,
    BILLING_PAGE_SET_ADD_PAYMENT_FOR_INVOICE,
    BILLING_PAGE_SET_INVOICE_FORM,
    BILLING_PAGE_SET_INVOICE_GENERATION_DIALOG,
    BILLING_PAGE_SET_INVOICE_LIST_FAST_SEARCH,
    BILLING_PAGE_SET_INVOICE_LIST_FILTER,
    BILLING_PAGE_SET_INVOICE_LIST_FILTER_MENU,
    BILLING_PAGE_SET_INVOICE_LIST_SORTING_OPTION,
    BILLING_PAGE_SET_INVOICE_TO_DELETE,
    BILLING_PAGE_SET_RELOAD_INVOICE_LIST,
    BILLING_PAGE_UPDATE_INVOICE_ITEM,
} from './billingPage.actions';
import {
    EGroupEvaluationGroupBy,
    ENTITIES_GET_INVOICE_GROUPED_EVALUATION_FULFILLED,
    ENTITIES_GET_INVOICE_LIST_PENDING,
    ENTITIES_GET_INVOICE_OPEN_LIST_FULFILLED,
    ENTITIES_POST_INVOICE_FULFILLED,
    ENTITIES_PUT_INVOICE_FULFILLED,
} from '../entities/entities.actions';

const billingPageReducer = (state = preloadedStateBillingPage, action: TReducerAction): IBillingPageState => {
    switch (action.type) {
        case ENTITIES_GET_INVOICE_OPEN_LIST_FULFILLED: {
            const actionCast = action as IGetInvoiceListAction;

            return {
                ...state,
                openSum: actionCast.payload.sum
            };
        }
        case BILLING_PAGE_ADD_INVOICE_ITEM: {
            const actionCast = action as ISetBillingPageAddInvoiceItemAction;

            const newState = {
                ...state
            }

            const item = actionCast.payload;
            if (newState.invoiceForm.items.length === 0) {
                item.ranking = 1;
            } else {
                item.ranking = newState.invoiceForm.items[newState.invoiceForm.items.length - 1].ranking + 1;
            }

            newState.recalculateInvoiceForm = true;
            newState.invoiceForm.items.push(actionCast.payload as IInvoiceItemForm)
            return newState;
        }

        case BILLING_PAGE_UPDATE_INVOICE_ITEM: {
            const actionCast = action as ISetBillingPageUpdateInvoiceItemAction;

            const newState = {
                ...state
            }

            newState.invoiceForm.items[actionCast.payload.index] = actionCast.payload.item as IInvoiceItemForm;
            newState.recalculateInvoiceForm = true;
            return newState;
        }
        case BILLING_PAGE_DELETE_INVOICE_ITEM: {
            const actionCast = action as ISetBillingPageDeleteInvoiceItemAction;

            return {
                ...state,
                recalculateInvoiceForm: true,
                invoiceForm: {
                    ...state.invoiceForm,
                    items: [
                        ...state.invoiceForm.items.slice(0, actionCast.payload),
                        ...state.invoiceForm.items.slice(actionCast.payload + 1)
                    ]
                }
            }
        }
        case BILLING_PAGE_CALCULATE_INVOICE_SUM_FOOTER: {
            const items = state.invoiceForm.items;

            let total = 0;
            items.map((item) => {
                total += item.priceTotal as number;
            });

            return {
                ...state,
                recalculateInvoiceForm: false,
                invoiceForm: {
                    ...state.invoiceForm,
                    totalNet: total,
                    totalNetAfterDiscount: total,
                    vatSum: total * 0.19,
                    totalGross: total * 1.19
                }
            }
        }
        case BILLING_PAGE_SET_ACTIVE_TAB: {
            const actionCast = action as ISetBillingPageActiveTabAction;

            return {
                ...state,
                activeTab: actionCast.payload
            }
        }
        case ENTITIES_GET_INVOICE_GROUPED_EVALUATION_FULFILLED: {
            const actionCast = action as IGetInvoiceEvaluationDataAction;
            let index = '';

            switch (actionCast.meta) {
                case EGroupEvaluationGroupBy.GROUP_BY_COMPANY:
                    index = 'byCompany';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_EMPLOYEE:
                    index = 'byEmployee';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_MONTH:
                    index = 'byMonth';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_MONTH_SUMMED_UP:
                    index = 'byMonthSummedUp';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_CANDIDATE_SOURCE:
                    index = 'byCandidateSource';
                    break;
                case EGroupEvaluationGroupBy.GROUP_BY_BRANCH:
                    index = 'byBranch';
                    break;
            }

            return {
                ...state,
                groupedEvaluationData: {
                    ...state.groupedEvaluationData,
                    [index]: actionCast.payload
                }
            }
        }
        case BILLING_PAGE_SET_INVOICE_FORM: {
            const actionCast = action as ISetBillingPageInvoiceFormAction;

            return {
                ...state,
                invoiceForm: {
                    ...state.invoiceForm,
                    ...actionCast.payload
                },
            }
        }
        case BILLING_PAGE_SET_INVOICE_TO_DELETE: {
            const actionCast = action as ISetBillingPageInvoiceToDeleteAction;

            return {
                ...state,
                invoiceToDelete: actionCast.payload
            }
        }
        case BILLING_PAGE_SET_ADD_INVOICE_DIALOG: {
            const actionCast = action as ISetBillingPageAddInvoiceDialogAction;

            return {
                ...state,
                addInvoice: actionCast.payload
            }
        }
        case BILLING_PAGE_SET_INVOICE_GENERATION_DIALOG: {
            const actionCast = action as ISetBillingPageInvoiceGenerationDialogAction;

            return {
                ...state,
                generateViewOpen: actionCast.payload
            }
        }

        case BILLING_PAGE_SET_INVOICE_LIST_SORTING_OPTION: {
            const actionCast = action as ISetBillingPageInvoiceListSortingOptionAction;
            return {
                ...state,
                sortingInvoiceList: {
                    [actionCast.payload.propertyToSort]: actionCast.payload.sortOption,
                },
            };
        }
        case BILLING_PAGE_SET_INVOICE_LIST_FAST_SEARCH: {
            const actionCast = action as ISetBillingPageInvoiceListFastSearchAction;

            return {
                ...state,
                filterInvoiceList: {
                    ...state.filterInvoiceList,
                    fastSearch: actionCast.payload,
                },
            };
        }
        case BILLING_PAGE_SET_INVOICE_LIST_FILTER: {
            const actionCast = action as ISetBillingPageInvoiceListFilterAction;

            return {
                ...state,
                filterInvoiceList: {
                    ...state.filterInvoiceList,
                    ...actionCast.payload,
                }
            };
        }
        case BILLING_PAGE_SET_INVOICE_LIST_FILTER_MENU: {
            const actionCast = action as ISetBillingPageInvoiceListFilterMenuAction;

            return {
                ...state,
                filterMenuInvoiceList: {
                    ...state.filterMenuInvoiceList,
                    ...actionCast.payload,
                }
            };
        }
        case BILLING_PAGE_SET_ADD_PAYMENT_FOR_INVOICE: {
            const actionCast = action as ISetBillingPageAddPaymentForInvoiceAction;

            return {
                ...state,
                addPaymentForInvoiceId: actionCast.payload
            }
        }
        case BILLING_PAGE_SET_RELOAD_INVOICE_LIST: {
            const actionCast = action as ISetBillingPageReloadInvoiceListAction;

            return {
                ...state,
                reloadInvoiceList: actionCast.payload
            }
        }
        case ENTITIES_GET_INVOICE_LIST_PENDING: {
            return {
                ...state,
                reloadInvoiceList: false
            }
        }
        case ENTITIES_PUT_INVOICE_FULFILLED: {
            const actionCast = action as IPutInvoiceAction;

            return {
                ...state,
                invoiceForm: actionCast.meta ? initialInvoiceFormState : state.invoiceForm,
                invoiceToEdit: actionCast.meta ? 0 : actionCast.payload.id,
                reloadInvoiceList: true
            }
        }
        case ENTITIES_POST_INVOICE_FULFILLED: {
            const actionCast = action as IPostInvoiceAction;

            return {
                ...state,
                addInvoice: false,
                invoiceToEdit: actionCast.payload.id,
                reloadInvoiceList: true
            }
        }
    }

    return state;
};

export default billingPageReducer;
