import React, { ReactNode } from 'react';
import { Link } from '@material-ui/core';
import { fixUrl } from '../../utils/application.utils';
import { EIcons, IconFactory } from '../Icons/IconFactory';

interface IProps {
    value: ReactNode;
    url?: string;
    formContent?: boolean;
    hideLaunchIcon?: boolean;
}

const UrlCellRenderer: React.FC<IProps> = (props) => {
    if (!props.url) {
        return null;
    }

    const hideLaunchIcon = props.hideLaunchIcon;

    return (
        <Link
            style={props.formContent ? {
                fontSize: 12,
                paddingBottom: 3,
                color: 'var(--brainz-color-text-secondary)',
                flexDirection: 'column',
                verticalAlign: 'middle'
            } : {
                color: 'var(--brainz-color-text-secondary)'
            }}
            href={'#'}
            onClick={(event) => {
                event.stopPropagation();
                window.open(fixUrl(props.url || ''));
            }}
            className={"alignVerticalCenter"}
            variant={'body1'}
        >
            {!hideLaunchIcon && IconFactory.getIconComponent(EIcons.Launch, {color: 'inerhit'})}
            {props.value}
        </Link>
    );
};

export default UrlCellRenderer;
