import React, { useEffect, useState } from 'react';
import {
    ECandidateSource,
    ESuggestionAction,
    ESuggestionProgressStatus,
    ESuggestionStatus,
    ICandidateAddress,
    ICandidateMailAddress,
    ICandidatePhoneNumber,
    ICandidateUrl,
    ICountry,
    IEmployeePartial,
    ISuggestion,
    ISuggestionProgressInformation,
    TCandidateUrlType,
} from '../../../definitions/entities.definition';
import { Rating } from '@material-ui/lab';
import StarIcon from '@material-ui/icons/Star';
import {
    getSuggestionAction,
    updateCandidateAction,
    updateSuggestionAction,
} from '../../../redux/entities/entities.actions';
import { Avatar, Badge, Button, Card, CardHeader, ClickAwayListener, Divider } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import CandidateProfilesRenderer from '../../../components/CustomCellRenderer/CandidateProfilesRenderer';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import ProgressStatusCellRenderer from '../../../components/CustomCellRenderer/ProgressStatusCellRenderer';
import CustomEditableValue from '../../../components/CustomInput/CustomEditableValue';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../selectors/app.selectors';
import { translate } from '../../../translation/translate.utils';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { COLOR_GREEN } from '../../../theme/theme';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import DateDiffCellRenderer from '../../../components/CustomCellRenderer/DateDiffCellRenderer';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import EmployeeAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import SuggestionChangeResponsibleView from '../../EditViews/Suggestion/components/SuggestionChangeResponsibleView';
import {
    API_URL,
    postCandidateAddressRouteConfig,
    postCandidateMailAddressRouteConfig,
    postCandidatePhoneRouteConfig,
    postCandidateUrlRouteConfig,
} from '../../../requests/routes';
import YesNoCellRenderer from '../../../components/CustomCellRenderer/YesNoCellRenderer';
import CustomContextMenu from '../../../components/CustomContextMenu/CustomContextMenu';
import {
    setVacancyPageSuggestionToAddActivityAction,
    setVacancyPageSuggestionToDeclineAction,
    setVacancyPageSuggestionToDeleteAction,
    setVacancyPageSuggestionToShowHistoryAction,
} from '../../../redux/vacancyPage/vacancyPage.actions';
import { setRecruitingPageStartRecruitingAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import customEditableStyles from '../../../components/CustomInput/CustomEditableValue.module.css';
import { ClientApi } from '../../../requests/ClientApi';
import { registerError } from '../../../redux/error/error.actions';
import CandidatePhoneListView from '../../EditViews/Candidate/components/components/CandidatePhoneListView';
import CandidateMailListView from '../../EditViews/Candidate/components/components/CandidateMailListView';
import CandidateAddressListView from '../../EditViews/Candidate/components/components/CandidateAddressListView';
import CountryAutocomplete from '../../../components/CustomInput/CustomAutocomplete/components/CountryAutocomplete';
import CandidateUrlTypeDropDown from '../../../components/CustomInput/CustomDropDownInput/CandidateUrlTypeDropDown';
import CandidateUrlListView from '../../EditViews/Candidate/components/components/CandidateUrlListView';
import CandidateDocumentUploadView from '../../EditViews/Candidate/components/components/CandidateDocumenUploadView';
import { getFormattedDateString } from '../../../utils/date.utils';
import { useCreateCandidateHook } from '../../CandidatePage/components/CandidatAddView.hooks';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import CustomInfo from '../../../components/CustomComponent/CustomInfo';
import CandidateInformationColumnCellRenderer
    from '../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import CandidateMergeView from '../../CandidatePage/CandidateMergeView';

interface IProps {
    suggestion: ISuggestion;
    parentView: EEntityView;
    rankingOptions?: {id: number, rating: number, ranking: number; fullName: string}[];
    displayStatusInformation?: boolean;
}

const RenderSuggestionListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const displayStatusInformation = props.displayStatusInformation;
    const suggestion = props.suggestion;
    const parentView = props.parentView;
    const expandOrCollapseItems = useSelector((store: IStore) => store.ui.expandOrCollapseSuggestionListItems);
    const [showDetails, setShowDetails] = useState(expandOrCollapseItems === 'expand');
    const rankingOptions = props.rankingOptions;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);

    const [uploadDocument, setUploadDocument] = useState(false);
    const [addUrl, setAddUrl] = useState('');
    const [addUrlType, setAddUrlType] = useState<TCandidateUrlType>('');
    const [addPhoneNumber, setAddPhoneNumber] = useState('');
    const [editUrls, setEditUrls] = useState(false);
    const [editPhoneNumbers, setEditPhoneNumbers] = useState(false);
    const [addMailAddress, setAddMailAddress] = useState('');
    const [editMailAddresses, setEditMailAddresses] = useState(false);
    const [addCity, setAddCity] = useState('');
    const [addStreet, setAddStreet] = useState('');
    const [addZipCode, setAddZipCode] = useState('');
    //@ts-ignore
    const [addCountry, setAddCountry] = useState<ICountry>(55);
    const [editAddresses, setEditAddresses] = useState(false);

    const [name, setName] = useState(suggestion.candidate.name);
    const [firstName, setFirstName] = useState(suggestion.candidate.firstName);
    const [externalReference, setExternalReference] = useState(suggestion.candidate.externalReference);
    const [suggestedBy, setSuggestedBy] = useState<IEmployeePartial | null>(suggestion.suggestedByEmployee || null);
    const [responsibleEmployee, setResponsibleEmployee] = useState<IEmployeePartial | null>(suggestion.responsibleEmployee || null);

    const [responsibleChangedViewOpen, setResponsibleChangedViewOpen] = useState(false);
    const [responsibleChanged, setResponsibleChanged] = useState(false);
    const [responsibleChangedComment, setResponsibleChangedComment] = useState('');

    const [checkDuplicates, setCheckDuplicates] = useState(false);
    const [hideInfo, setHideInfo] = useState(false);
    const [mergeCandidateId, setMergeCandidateId] = useState(0);
    const {duplicateCandidates} = useCreateCandidateHook(name, firstName, externalReference || null, suggestion.candidate.id, checkDuplicates, setCheckDuplicates);

    const totalContactCount = Object.entries(suggestion.currentProgressInformation.contactCounts)
        .filter((contactWithCount) => contactWithCount[0] !== ESuggestionAction.ACTION_MESSAGE_PORTAL)
        .reduce((n, contactWithCount) => n + contactWithCount[1], 0);

    useEffect(() => {
        setSuggestedBy(suggestion.suggestedByEmployee || null);
        setResponsibleEmployee(suggestion.responsibleEmployee || null);
        setResponsibleChanged(false);
        setResponsibleChangedComment('');
    }, [suggestion])

    useEffect(() => {
        setShowDetails(expandOrCollapseItems === 'expand');
    }, [expandOrCollapseItems])

    useEffect(() => {
        if (!responsibleEmployee) {
            return;
        }

        if (suggestion.responsibleEmployee.id !== responsibleEmployee.id && responsibleEmployee.id !== loggedInEmployee.id) {
            setResponsibleChangedViewOpen(true);
        }
    }, [responsibleEmployee]);

    const getRating = (suggestion: ISuggestion) => {
        return <Rating
            size={"large"}
            value={suggestion.rating/2}
            max={3}
            icon={<StarIcon style={{ width: "1.2em", height: "1.2em", pointerEvents: "auto" }} />}
            precision={0.5}
            onChange={(event, value) => {
                if (value) {
                    dispatch(updateSuggestionAction(token, {
                        id: suggestion.id,
                        rating: value * 2
                    }));
                }
            }}
            onClick={(event) => {
                event.stopPropagation();
            }}
        />
    }

    const renderCandidatePhone = (suggestion: ISuggestion) => {
        const hasPhoneNumber = suggestion.candidate.candidateProfiles.phoneNumbers.length > 0;

        if (editPhoneNumbers) {
            return <ClickAwayListener mouseEvent={"onClick"} onClickAway={(event) => {
                    if (event.target.localName === 'body') {
                        event.preventDefault();
                        return;
                    }
                    event.preventDefault();
                    event.stopPropagation();
                    setEditPhoneNumbers(false);
                }}>
                    <div>
                        <CandidatePhoneListView
                            records={suggestion.candidate.candidateProfiles.phoneNumbers}
                            suggestionId={suggestion.id}
                            candidateId={suggestion.candidate.id}
                        />
                    </div>
                </ClickAwayListener>;
        }

        if (hasPhoneNumber) {
            return <div className={customEditableStyles.light + " padding5"} onClick={() => setEditPhoneNumbers(true)}>
                <div className={" alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.Phone, {color: COLOR_GREEN})}
                </div>
            </div>;
        }

        return <CustomEditableValue
            theme={'light'}
            size={'small'}
            width={300}
            value={suggestion.candidate.candidateProfiles.phoneNumbers[0]?.phoneNumber}
            input={
                <CustomTextField
                    size={'small'}
                    label={'Hinzufügen'}
                    autoFocus
                    value={addPhoneNumber}
                    onChange={(value) => setAddPhoneNumber(value)}
                    width={300}
                />
            }
            onSave={() => {
                if (!hasPhoneNumber && !addPhoneNumber) {
                    return Promise.reject();
                }

                return ClientApi.request(postCandidatePhoneRouteConfig, {
                    token,
                    candidateId: suggestion.candidate.id,
                    phoneNumber: addPhoneNumber,
                    default: suggestion.candidate.candidateProfiles.phoneNumbers.length === 0
                }).then((phone: ICandidatePhoneNumber) => {
                    setAddPhoneNumber('');
                    dispatch(getSuggestionAction(token, suggestion.id));
                }).catch(() => {
                    dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
                });
            }}
            emptyValue={
                <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.Phone)}
                </div>
            }
        />;
    }

    const renderCandidateMailAddress = (suggestion: ISuggestion) => {
        const hasMailAddressNumber = suggestion.candidate.candidateProfiles.mailAddresses.length > 0;

        if (editMailAddresses) {
            return <ClickAwayListener mouseEvent={"onMouseUp"} onClickAway={(event) => {
                if (event.target.localName === 'body') {
                    event.preventDefault();
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                setEditMailAddresses(false);
            }}>
                <div>
                    <CandidateMailListView
                        records={suggestion.candidate.candidateProfiles.mailAddresses}
                        suggestionId={suggestion.id}
                        candidateId={suggestion.candidate.id}
                    />
                </div>
            </ClickAwayListener>;
        }

        if (hasMailAddressNumber) {
            return <div className={customEditableStyles.light + " padding5"} onClick={() => setEditMailAddresses(true)}>
                <div className={" alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.EMail, {color: COLOR_GREEN})}
                </div>
            </div>;
        }

        return <CustomEditableValue
            theme={'light'}
            size={'small'}
            width={300}
            value={suggestion.candidate.candidateProfiles.mailAddresses[0]?.mailAddress}
            input={
                <CustomTextField
                    size={'small'}
                    label={'Hinzufügen'}
                    autoFocus
                    value={addMailAddress}
                    onChange={(value) => setAddMailAddress(value)}
                    width={300}
                />
            }
            onSave={() => {
                if (!hasMailAddressNumber && !addMailAddress) {
                    return Promise.reject();
                }

                return ClientApi.request(postCandidateMailAddressRouteConfig, {
                    token,
                    candidateId: suggestion.candidate.id,
                    mailAddress: addMailAddress,
                    default: suggestion.candidate.candidateProfiles.mailAddresses.length === 0
                }).then((mailAddress: ICandidateMailAddress) => {
                    setAddMailAddress('');
                    dispatch(getSuggestionAction(token, suggestion.id));
                }).catch(() => {
                    dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
                });
            }}
            emptyValue={
                <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.EMail)}
                </div>
            }
        />;
    }

    const renderCandidateAddress = (suggestion: ISuggestion) => {
        const hasAddressNumber = suggestion.candidate.candidateProfiles.addresses.length > 0;

        if (editAddresses) {
            return <ClickAwayListener mouseEvent={"onMouseUp"} onClickAway={(event) => {
                if (event.target.localName === 'body') {
                    event.preventDefault();
                    return;
                }
                event.preventDefault();
                event.stopPropagation();
                setEditAddresses(false);
            }}>
                <div>
                    <CandidateAddressListView
                        records={suggestion.candidate.candidateProfiles.addresses}
                        suggestionId={suggestion.id}
                        candidateId={suggestion.candidate.id}
                    />
                </div>
            </ClickAwayListener>;
        }

        if (hasAddressNumber) {
            return <div className={customEditableStyles.light + " padding5"} onClick={() => setEditAddresses(true)}>
                <div className={" alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.LocationOn, {color: COLOR_GREEN})}
                </div>
            </div>;
        }

        return <CustomEditableValue
            theme={'light'}
            size={'small'}
            width={300}
            value={suggestion.candidate.candidateProfiles.addresses[0]?.address}
            input={
                <div className={"flexContainerColumn"}>
                    <div className={"flexContainerRow flex1 gap5"}>
                        <CustomTextField
                            required
                            size={'small'}
                            autoFocus
                            value={addZipCode}
                            onChange={(value) => setAddZipCode(value)}
                            label={translate('misc.zipCode')}
                            width={100}
                        />
                        <CustomTextField
                            required
                            size={'small'}
                            label={translate('misc.city')}
                            value={addCity}
                            onChange={(value) => setAddCity(value)}
                        />
                    </div>
                    <CountryAutocomplete
                        value={addCountry}
                        size={'small'}
                        onChange={(country) => setAddCountry(country)}
                    />
                    <CustomTextField
                        value={addStreet}
                        size={'small'}
                        onChange={(value) => setAddStreet(value)}
                        label={translate('misc.address')}
                    />
                </div>
            }
            onSave={() => {
                if (!hasAddressNumber && !addCity) {
                    return Promise.reject();
                }

                return ClientApi.request(postCandidateAddressRouteConfig, {
                    token,
                    candidateId: suggestion.candidate.id,
                    address: addStreet,
                    city: addCity,
                    zipCode: addZipCode,
                    countryId: addCountry ? addCountry.id : undefined,
                    default: suggestion.candidate.candidateProfiles.addresses.length === 0
                }).then((address: ICandidateAddress) => {
                    setAddCity('');
                    setAddZipCode('');
                    setAddStreet('');
                    setAddCountry(55);
                    dispatch(getSuggestionAction(token, suggestion.id));
                }).catch(() => {
                    dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
                });
            }}
            emptyValue={
                <div className={"alignHorizontalCenter alignVerticalCenter height100"}>
                    {IconFactory.getIconComponent(EIcons.LocationOn)}
                </div>
            }
        />;
    }

    const renderCandidateUrl = (suggestion: ISuggestion) => {
        const hasUrl = suggestion.candidate.candidateProfiles.urls.length > 0 || suggestion.candidate.candidateProfiles.arbeitsamtUrl;

        if (editUrls) {
            return <ClickAwayListener mouseEvent={"onClick"} onClickAway={(event) => {
                if (event.target.localName === 'body') {
                    event.preventDefault();
                    return;
                }

                event.preventDefault();
                event.stopPropagation();
                setEditUrls(false);
            }}>
                <div>
                    <CandidateUrlListView
                        records={suggestion.candidate.candidateProfiles.urls}
                        suggestionId={suggestion.id}
                        candidateId={suggestion.candidate.id}
                    />
                </div>
            </ClickAwayListener>;
        }

        if (hasUrl) {
            return <div className={customEditableStyles.light + " padding5"} onClick={() => setEditUrls(true)}>
                <div className={"flexContainerRow alignVerticalCenter flexWrap"}>
                    <CandidateProfilesRenderer profiles={suggestion.candidate.candidateProfiles} />
                </div>
            </div>;
        }

        return <CustomEditableValue
            theme={'light'}
            size={'small'}
            width={300}
            value={suggestion.candidate.candidateProfiles.urls[0]?.url}
            input={
                <>
                    <CandidateUrlTypeDropDown
                        value={addUrlType}
                        disablePortal
                        size={'small'}
                        onChange={(event) => setAddUrlType(event.target.value)}
                    />
                    <CustomTextField
                        size={'small'}
                        label={'Hinzufügen'}
                        autoFocus
                        value={addUrl}
                        onChange={(value) => setAddUrl(value)}
                        width={300}
                    />
                </>
            }
            onSave={() => {
                if (!hasUrl && !addUrl) {
                    return Promise.reject();
                }

                return ClientApi.request(postCandidateUrlRouteConfig, {
                    token,
                    candidateId: suggestion.candidate.id,
                    url: addUrl,
                    type: addUrlType
                }).then((phone: ICandidateUrl) => {
                    setAddUrl('');
                    dispatch(getSuggestionAction(token, suggestion.id));
                }).catch(() => {
                    dispatch(registerError('Datensatz konnte nicht hinzugefügt werden'));
                });
            }}
        />;
    }

    const renderCandidate = (suggestion: ISuggestion) => {
        return <>
            <CandidateDocumentUploadView
                candidateId={suggestion.candidate.id}
                open={uploadDocument}
                onClose={() => {
                    setUploadDocument(false);
                }}
                successCallback={(response) => {
                    setUploadDocument(false);
                    dispatch(getSuggestionAction(token, suggestion.id));
                }}
            />
            <div className={"flexContainerColumn"}>
                <div className={"flexContainerRow alignVerticalCenter formLabelSmall marginBottom5"}>
                    Tel.
                    {editPhoneNumbers &&
                        <CustomClickableIcon
                            className={"alignHorizontalRight"}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditPhoneNumbers(false);
                            }}
                            icon={EIcons.Cancel}
                        />
                    }
                </div>
                {renderCandidatePhone(suggestion)}
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn"}>
                <div className={"flexContainerRow alignVerticalCenter formLabelSmall marginBottom5"}>
                    Mail
                    {editMailAddresses &&
                        <CustomClickableIcon
                            className={"alignHorizontalRight"}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditMailAddresses(false);
                            }}
                            icon={EIcons.Cancel}
                        />
                    }
                </div>
                {renderCandidateMailAddress(suggestion)}
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn"}>
                <div className={"flexContainerRow alignVerticalCenter formLabelSmall marginBottom5"}>
                    Adr.
                    {editAddresses &&
                        <CustomClickableIcon
                            className={"alignHorizontalRight"}
                            onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                                setEditAddresses(false);
                            }}
                            icon={EIcons.Cancel}
                        />
                    }
                </div>
                {renderCandidateAddress(suggestion)}
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn"}>
                <div className={"formLabelSmall marginBottom5"}>Unterlagen</div>
                <div className={"alignHorizontalCenter alignVerticalCenter padding5 "+ customEditableStyles.light} onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    setUploadDocument(true)
                }}>
                    <YesNoCellRenderer value={suggestion.candidate.type === 'candidate'}/>
                </div>
            </div>
            <Divider orientation={"vertical"}/>
            <div className={"flexContainerColumn flex1"}>
                <div className={"flexContainerRow alignVerticalCenter formLabelSmall hoverContainer marginBottom5"}>
                    {suggestion.candidate.name ? 'Name' : 'Referenznr'}
                </div>
                <CustomEditableValue
                    theme={'light'}
                    value={suggestion.responsibleEmployee}
                    rows={8}
                    input={<div className={"flexContainerColumn flex1"}>
                        <CustomTextField
                            autoFocus
                            size={"small"}
                            value={firstName}
                            onChange={(value) => setFirstName(value)}
                            label={translate('pages.candidate.properties.firstName')}
                        />
                        <CustomTextField
                            size={"small"}
                            value={name}
                            onChange={(value) => setName(value)}
                            label={translate('pages.candidate.properties.name')}
                        />
                        <CustomTextField
                            size={"small"}
                            value={externalReference}
                            onChange={(value) => setExternalReference(value)}
                            label={translate('pages.candidate.properties.externalReference')}
                        />
                    </div>}
                    onSave={() => {
                        if (firstName === suggestion.candidate.firstName && name === suggestion.candidate.name && externalReference === suggestion.candidate.externalReference) {
                            return Promise.reject();
                        }

                        setCheckDuplicates(true);
                        return dispatch(updateCandidateAction(token, {
                            id: suggestion.candidate.id,
                            firstName,
                            name,
                            externalReference
                        })).then(() => {
                            dispatch(getSuggestionAction(token, suggestion.id));
                        });
                    }}
                >
                    <div className={"alignVerticalCenter height100"} style={{fontWeight: 500, fontStyle: 'italic'}}>
                        {suggestion.candidate.candidateProfiles.photoId ?
                            <Avatar
                                style={{width: 25, height: 25}}
                                src={API_URL + "/candidate-image/" + suggestion.candidate.candidateProfiles.photoId + "/preview?Authorization=" + token}
                            /> :
                            <Avatar style={{width: 25, height: 25}}>
                                {suggestion.candidate.firstName && suggestion.candidate.name &&
                                    <>{suggestion.candidate.firstName[0]}{suggestion.candidate.name[0]}</>
                                }
                            </Avatar>
                        }
                        &nbsp;
                        {suggestion.ranking > 0 && suggestion.ranking + '. '}{suggestion.candidate.fullName || suggestion.candidate.externalReference}
                        &nbsp;
                        {suggestion.candidate.name && suggestion.candidate.externalReference && <><br/>Referenznr.: {suggestion.candidate.externalReference}</>}
                        &nbsp;
                        <CustomClickableIcon
                            tooltip={"Kandidat öffnen"}
                            onClick={() => dispatch(setUiEditView(EEntityView.candidate, suggestion.candidate.id))}
                            icon={EIcons.Launch}
                        />
                    </div>
                </CustomEditableValue>
                {(duplicateCandidates.length > 0 && !hideInfo) &&
                <>
                    <PaperRow>
                        <CandidateMergeView
                            open={Boolean(mergeCandidateId)}
                            candidateId={suggestion.candidate.id}
                            mergeCandidateId={mergeCandidateId}
                            onClose={() => setMergeCandidateId(0)}
                            reloadSuggestionId={suggestion.id}
                            onSuccess={() => setHideInfo(true)}
                        />
                        <CustomInfo>
                            <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen/gleicher Referenz</h4>
                            <div className={"flexContainerRow flexWrap flex1 gap10"}>
                                {duplicateCandidates.map((candidate) => <>
                                    <div className={"flexContainerRow CandidateInformationPanel"}>
                                            <CandidateInformationColumnCellRenderer
                                                key={candidate.id}
                                                showDetails
                                                token={token}
                                                candidate={candidate}
                                                buttonConfig={{
                                                    label: 'Zusammenführen',
                                                    action: () => setMergeCandidateId(candidate.id)
                                                }}
                                            />
                                    </div>
                                </>)}
                            </div>
                            <Button
                                variant={"contained"}
                                color="secondary"
                                size="small"
                                onClick={() => setHideInfo(true)}
                                style={{marginTop: 5}}
                            >
                                Ausblenden
                            </Button>
                        </CustomInfo>
                    </PaperRow>
                </>
                }
            </div>
        </>;
    }

    const renderSuggestionContactAttempts = (progressInformation: ISuggestionProgressInformation) => {
        return <>
            <Divider orientation={"vertical"} style={{marginLeft: 10, height: 'auto'}}/>
            <div className={"marginBottom5"}>
                <div className={"formLabelSmall alignLeft marginTop5"}>Kontakt-Versuche ({totalContactCount})</div>
                <div
                    onClick={() => dispatch(setVacancyPageSuggestionToAddActivityAction(suggestion.id))}
                    className={"flexContainerRow flexWrap alignVerticalCenter gap20 marginTop10 padding5 " +  customEditableStyles.light}>
                    {Object.entries(progressInformation.contactCounts).map((contactWithCount) => {
                        const contactType = contactWithCount[0];
                        const contactCount = contactWithCount[1];

                        if (contactCount === 0 || contactType === ESuggestionAction.ACTION_MESSAGE_PORTAL) {
                            return null;
                        }

                        switch (contactType) {
                            case ESuggestionAction.ACTION_MESSAGE_MAIL:
                                return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.EMail)}</Badge></>;
                            case ESuggestionAction.ACTION_LETTER_SEND:
                                return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.Send)}</Badge></>;
                            case ESuggestionAction.ACTION_MESSAGE_MOBILE:
                                return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.SmartPhone)}</Badge></>;
                            case ESuggestionAction.ACTION_CALL_NOT_REACHED:
                                return <><Badge color={"error"} badgeContent={contactCount}>{IconFactory.getIconComponent(EIcons.PhoneDisabled)}</Badge></>;
                            default:
                                return <><Badge color={"error"} badgeContent={contactCount}><CandidateSourceRenderer source={contactType as ECandidateSource}/></Badge></>
                        }
                    })}
                    {totalContactCount === 0 &&
                        <div className={"formContent"}>Hier klicken zum eintragen</div>
                    }
                </div>
            </div>
        </>;
    }

    const renderSuggestionProgressInformation = (suggestion: ISuggestion) => {
        const dispatch = useDispatch();
        const progressInformation = suggestion.currentProgressInformation;

        if (suggestion.status === ESuggestionStatus.DECLINED) {
            return <>
                <Divider orientation={"vertical"}/>
                <div className={"flexContainerColumn alignLeft"} style={{width: 200}}>
                    <div className={"formLabelSmall marginBottom5"}>Absage {suggestion.declineDate && <>am {getFormattedDateString(suggestion.declineDate)}</> }</div>
                    <div className={"formContent"}>
                        Grund: {translate('pages.vacancy.declineSuggestionReasonValues.' + suggestion.declineReason)}<br/>
                    </div>
                </div>
            </>;
        }

        if (suggestion.callAppointmentAt) {
            return <>
                <Divider orientation={"vertical"}/>
                <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setVacancyPageSuggestionToShowHistoryAction(suggestion.id))}>
                    <div className={"formLabelSmall marginBottom5"}>Call</div>
                    <div className={"formContent padding5 " + customEditableStyles.light}>am {getFormattedDateString(suggestion.callAppointmentAt)}</div>
                </div>
            </>;
        }

        if (progressInformation.isInCallCoordination) {
            return <>
                <Divider orientation={"vertical"}/>
                <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setVacancyPageSuggestionToShowHistoryAction(suggestion.id))}>
                    <div className={"formLabelSmall marginBottom5"}>Call-Koord.</div>
                    <div className={"formContent padding5 " + customEditableStyles.light}>letzte Aktivität: {getFormattedDateString(suggestion.lastActivity)}</div>
                </div>
            </>;
        }

        if (suggestion.notReachableUntil) {
            return <>
                <Divider orientation={"vertical"}/>
                <div className={"flexContainerColumn alignLeft cursorPointer"} onClick={() => dispatch(setVacancyPageSuggestionToShowHistoryAction(suggestion.id))}>
                    <div className={"formLabelSmall marginBottom5"}>Nicht erreichbar</div>
                    <div className={"formContent padding5 " + customEditableStyles.light}>bis {getFormattedDateString(suggestion.notReachableUntil)}</div>
                </div>
            </>;
        }

        return null;
    }

    const toggleDetails = () => {
        if (document.getElementById('editableValue')) {
            return;
        }
        setShowDetails(!showDetails);
    }

    return <>
        <SuggestionChangeResponsibleView
            onClose={() => {
                setResponsibleChanged(false);
                setResponsibleChangedViewOpen(false);
            }}
            onSave={(comment) => {
                setResponsibleChanged(true);
                setResponsibleChangedComment(comment);
            }}
            open={responsibleChangedViewOpen}
            newResponsibleName={responsibleEmployee?.fullName}
        />
        <div>
            <Card className={styles.root + ' hoverContainer'}>
                <CustomContextMenu
                    menuHeading={(entity) => {
                        const entityCast = entity as ISuggestion
                        return entityCast.candidate.fullName;
                    }}
                    index={suggestion.id}
                    entityId={suggestion.id}
                    entity={suggestion}
                    menuItems={[{
                        label: 'Kandidat öffnen',
                        icon: EIcons.Person,
                        action: () => dispatch(setUiEditView(EEntityView.candidate, suggestion.candidate.id)),
                        condition: () => parentView !== EEntityView.candidate
                    }, {
                        label: 'Historie öffnen',
                        icon: EIcons.History,
                        action: () => dispatch(setVacancyPageSuggestionToShowHistoryAction(suggestion.id))
                    }, {
                        label: 'Vakanz öffnen',
                        icon: EIcons.Work,
                        action: () => dispatch(setUiEditView(EEntityView.vacancy, suggestion.vacancy.id)),
                        condition: () => parentView !== EEntityView.vacancy
                    }, {
                        label: 'Prozess öffnen',
                        icon: EIcons.ThumbsUpDown,
                        action: () => dispatch(setUiEditView(EEntityView.recruiting, suggestion.recruiting.id)),
                        condition: (entity) => {
                            const entityCast = entity as ISuggestion;
                            return entityCast.status === ESuggestionStatus.ACCEPTED;
                        }
                    }, {
                        label: 'Aktivität hinzufügen',
                        icon: EIcons.Add,
                        action: () => dispatch(setVacancyPageSuggestionToAddActivityAction(suggestion.id))
                    }, {
                        label: 'Favorit vorschlagen/Prozess starten',
                        icon: EIcons.ThumbUp,
                        action: () => dispatch(setRecruitingPageStartRecruitingAction({
                            open: true,
                            candidateId: suggestion.candidate.id,
                            vacancyId: suggestion.vacancy.id,
                            suggestedByEmployeeId: suggestion.suggestedByEmployee.id,
                            responsibleEmployeeId: suggestion.responsibleEmployee.id
                        })),
                        condition: (entity) => {
                            const entityCast = entity as ISuggestion;
                            return entityCast.status !== ESuggestionStatus.ACCEPTED;
                        }
                    }, {
                        label: 'Favorit ablehnen',
                        icon: EIcons.ThumbDown,
                        action: () => dispatch(setVacancyPageSuggestionToDeclineAction(suggestion.id, false)),
                        condition: (entity) => {
                            const entityCast = entity as ISuggestion;
                            return entityCast.status !== ESuggestionStatus.DECLINED;
                        }
                    }, {
                        label: 'Eintrag löschen',
                        icon: EIcons.Delete,
                        action: () => dispatch(setVacancyPageSuggestionToDeleteAction(suggestion.id))
                    }]}
                >
                    <CardHeader
                        title={
                            <>
                                <div className={"flexColumn"}>
                                    <div className={"flex"}>
                                        <div
                                            className={"flex1 flexContainerRow cursorPointer gap5"}
                                            onClick={toggleDetails}
                                        >
                                            <div className={"flexContainerColumn"}>
                                                <div className={"formLabelSmall marginBottom5"}>Kontakt</div>
                                                <div className={"flexContainerColumn alignVerticalCenter alignHorizontalCenter padding5"}>
                                                    {suggestion.progressStatus === ESuggestionProgressStatus.RED
                                                    ?
                                                        <Badge color={"primary"} badgeContent={totalContactCount}>
                                                            <ProgressStatusCellRenderer
                                                                status={suggestion.progressStatus}
                                                                showAsTooltip
                                                            />
                                                        </Badge>
                                                    :
                                                        <ProgressStatusCellRenderer
                                                            status={suggestion.progressStatus}
                                                            showAsTooltip
                                                        />
                                                    }
                                                </div>
                                            </div>
                                            <Divider orientation={"vertical"}/>
                                            <div className={"flexContainerColumn"}>
                                                <div className={"formLabelSmall marginBottom5"}>Quelle</div>
                                                <div className={"alignHorizontalCenter alignVerticalCenter padding5"}>
                                                    <CandidateSourceRenderer source={suggestion.source}/>
                                                </div>
                                            </div>
                                            <Divider orientation={"vertical"}/>
                                            {parentView !== EEntityView.candidate &&
                                                <>{renderCandidate(suggestion)}</>
                                            }
                                            {parentView !== EEntityView.vacancy &&
                                                <div className={"flexContainerColumn"}>
                                                    <div className={"formLabelSmall marginBottom5"}>Vakanz</div>
                                                    <div className={"alignVerticalCenter"}>
                                                        {suggestion.vacancy.title}
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={"flex flexAutoGrow alignRight gap5"}>
                                            {displayStatusInformation &&
                                                <>
                                                    <Divider orientation={"vertical"}/>
                                                    <div className={"flexContainerColumn"}>
                                                        <div className={"formLabelSmall marginBottom5"}>Status</div>
                                                        <div className={"alignVerticalCenter formContent"}>
                                                            {translate('pages.recruiting.suggestionStatusValues.' + suggestion.status)}
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {renderSuggestionProgressInformation(suggestion)}
                                            <Divider orientation={"vertical"}/>
                                            <div className={"flexContainerColumn"} style={showDetails ? {width: 150} : {}}>
                                                <div className={"formLabelSmall alignLeft marginBottom5"}>Bewertung</div>
                                                <div className={"alignLeft " + customEditableStyles.light}>
                                                    {getRating(suggestion)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {showDetails &&
                                        <>
                                        <Divider style={{height: 1}}/>
                                        <div className={"flexContainerRow flex1 gap5"}>
                                            <div className={"flexContainerColumn marginTop5 flex1"} style={{minWidth: 400}}>
                                                <div className={"formLabelSmall marginBottom5"}>Kommentar</div>
                                                <CustomEditableValue
                                                    theme={'light'}
                                                    value={suggestion.comment}
                                                    rows={8}
                                                    className={"height100"}
                                                    onSave={(comment) => {
                                                        if (comment === suggestion.comment) {
                                                            return Promise.reject();
                                                        }

                                                        return dispatch(updateSuggestionAction(
                                                            token,
                                                            {
                                                                id: suggestion.id,
                                                                comment
                                                            }
                                                        ))
                                                    }}
                                                >
                                                    <div className={"flexContainerColumn height100 formContent"}>
                                                        <Nl2brCellRenderer value={suggestion.comment}/>
                                                    </div>
                                                </CustomEditableValue>
                                            </div>
                                            {suggestion.status === ESuggestionStatus.DECLINED &&
                                                <>
                                                    <div className={"flex flexAutoGrow gap5"} style={{width: 216}}>
                                                        <Divider orientation={"vertical"} style={{marginLeft: 10, height: 'auto'}}/>
                                                        <div className={"flexContainerColumn marginTop5"}>
                                                            <div className={"formLabelSmall marginBottom5"}>Kommentar zur Absage</div>
                                                            <CustomEditableValue
                                                                theme={'light'}
                                                                value={suggestion.declineReasonComment}
                                                                className={"height100"}
                                                                rows={8}
                                                                onSave={(declineReasonComment) => dispatch(updateSuggestionAction(
                                                                    token,
                                                                    {
                                                                        id: suggestion.id,
                                                                        declineReasonComment
                                                                    }
                                                                ))}
                                                            >
                                                                <div className={"flexContainerColumn height100 formContent"}>
                                                                    <Nl2brCellRenderer value={suggestion.declineReasonComment}/>
                                                                </div>
                                                            </CustomEditableValue>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            <Divider orientation={"vertical"} style={{height: 'auto'}}/>
                                            <div className={"flexContainerColumn"} style={{width: 150}}>
                                                <div className={"flexContainerRow alignVerticalCenter formLabelSmall marginBottom5"}>
                                                    Profile
                                                    {editUrls &&
                                                        <CustomClickableIcon
                                                            className={"alignHorizontalRight"}
                                                            onClick={(event) => {
                                                                event.preventDefault();
                                                                event.stopPropagation();
                                                                setEditUrls(false);
                                                            }}
                                                            icon={EIcons.Cancel}
                                                        />
                                                    }
                                                </div>
                                                {renderCandidateUrl(suggestion)}
                                                <Divider/>
                                                {renderSuggestionContactAttempts(suggestion.currentProgressInformation)}
                                                {suggestion.status === ESuggestionStatus.OPEN &&
                                                    <>
                                                        <Divider/>
                                                        <div className={"formLabelSmall marginBottom5"}>Letzte Aktivität</div>
                                                        <div className={"flexContainerRow formContent padding5 " + customEditableStyles.light}>
                                                            <CustomClickableIcon
                                                                tooltip={"Historie einsehen"}
                                                                icon={EIcons.History}
                                                                iconStyle={{width: 24, height: 24}}
                                                                onClick={() => dispatch(setVacancyPageSuggestionToShowHistoryAction(suggestion.id))}
                                                            >
                                                                &nbsp;<DateDiffCellRenderer date={suggestion.lastActivity} hideTooltip />
                                                            </CustomClickableIcon>
                                                        </div>
                                                    </>
                                                }
                                                <Divider/>
                                                <div className={"flexContainerRow alignVerticalCenter flexWrap"}>
                                                    <div className={"formLabelSmall marginBottom5"}>Mitarbeiter</div>
                                                </div>
                                                <div className={"flexContainerRow formContent marginLeft5"}>
                                                    <CustomEditableValue
                                                        theme={'light'}
                                                        value={suggestion.responsibleEmployee}
                                                        className={"flex1"}
                                                        rows={8}
                                                        input={<div className={"flexContainerColumn"} style={{width: 200}}>
                                                            <EmployeeAutocomplete
                                                                label={translate('pages.vacancy.suggestedByEmployee')}
                                                                value={suggestedBy || null}
                                                                size={"small"}
                                                                required
                                                                onChange={( value) => setSuggestedBy(value)}
                                                            />
                                                            <EmployeeAutocomplete
                                                                label={translate('misc.responsibleEmployee')}
                                                                value={responsibleEmployee || null}
                                                                size={"small"}
                                                                required
                                                                onChange={(value) => setResponsibleEmployee(value)}
                                                            />
                                                        </div>}
                                                        onSave={() => {
                                                            if (suggestedBy === suggestion.suggestedByEmployee && responsibleEmployee === suggestion.responsibleEmployee) {
                                                                return Promise.reject();
                                                            }

                                                            return dispatch(updateSuggestionAction(token, {
                                                                id: suggestion.id,
                                                                responsibleEmployeeId: responsibleEmployee?.id,
                                                                suggestedByEmployeeId: suggestedBy?.id,
                                                                responsibleChanged,
                                                                responsibleChangedComment,
                                                            }))
                                                        }}
                                                    >
                                                        {suggestion.responsibleEmployee.id !== suggestion.suggestedByEmployee.id
                                                            ? <div className={"flex"}>
                                                                <EmployeeCellRenderer size={"small"} extraTooltip={"Vogeschlagen von: "} employee={suggestion.suggestedByEmployee}/>
                                                                &nbsp;/&nbsp;
                                                                <EmployeeCellRenderer size={"small"} extraTooltip={"Verantwortlicher: "} employee={suggestion.responsibleEmployee}/>
                                                            </div>
                                                            : <EmployeeCellRenderer size={"small"} employee={suggestion.suggestedByEmployee}/>
                                                        }
                                                    </CustomEditableValue>
                                                </div>
                                            </div>
                                        </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                        titleTypographyProps={{
                            variant: "body2"
                        }}
                        subheaderTypographyProps={{
                            variant: "body2"
                        }}
                    />
                </CustomContextMenu>
            </Card>
        </div>
    </>;
}

export default RenderSuggestionListItem;
