import { useCallback, useEffect, useState } from 'react';
import { ICandidateListModel } from '../../../definitions/entities.definition';
import { getToken } from '../../../selectors/app.selectors';
import { useSelector } from 'react-redux';
import { debounce } from '@material-ui/core';
import { ClientApi } from '../../../requests/ClientApi';
import { getCandidateDuplicatesRouteConfig } from '../../../requests/routes';

export const useCreateCandidateHook = (name: null|string, firstName: null|string, externalReference: null|string, ignoreId?: number, check?: boolean, setCheck?: (value: boolean) => void) => {
    check = check === undefined ? true : check;

    const token = useSelector(getToken);
    const [duplicateCandidates, setDuplicateCandidates] = useState<ICandidateListModel[]>([]);

    const changeHandler = (callable: () => void) => {
        callable();
    }
    const debouncedChangeHandler = useCallback(
        debounce(changeHandler, 500), []
    );

    useEffect(() => {
        if ((!name || !firstName) && !externalReference) {
            setDuplicateCandidates([]);
        }

        debouncedChangeHandler(() => doRequest(name, firstName, externalReference))
    }, [name, firstName, externalReference, check]);

    const doRequest = (name: null|string, firstName: null|string, externalReference: null|string) => {
        if (!check) {
            return;
        }

        ClientApi.request(getCandidateDuplicatesRouteConfig, {
            token,
            firstName,
            name,
            externalReference,
            ignoreId
        }).then((result: ICandidateListModel[]) => {
            setDuplicateCandidates(result);
            if (setCheck) {
                setCheck(false);
            }
            return;
        });
    }

    return {duplicateCandidates}
}
