import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../definitions/redux/store.definitions';
import { setUiEncouragementOpenAction } from './ui/ui.actions';
import { getToken } from '../selectors/app.selectors';
import { getVacancyPageOpenListRequestObject } from '../selectors/vacancy.selectors';
import { getAllOpenVacanciesAction } from './entities/entities.actions';
import { celebrationAnimation } from '../utils/celebrationAnimation.utils';
import { registerSuccess } from './error/error.actions';

const ReduxDelegator: React.FC = () => {
    const dispatch = useDispatch();
    const recruitingStatusChangedToContract = useSelector((state: IStore) => state.entities.recruiting.statusChangedToContract);
    const recruitingStatusChangedToAbort = useSelector((state: IStore) => state.entities.recruiting.statusChangedToAbort);
    const requestObjectOpenVacancyList = useSelector(getVacancyPageOpenListRequestObject);
    const contractRecruiting = useSelector((state: IStore) => state.entities.recruiting.byId[state.entities.recruiting.statusChangedToContractId]);
    const token = useSelector(getToken);

    useEffect(() => {
        if (recruitingStatusChangedToContract) {
            celebrationAnimation(contractRecruiting.responsibleEmployee.id);
            dispatch(registerSuccess('Herzlichen Glückwunsch zur Vermittlung'));
            dispatch(getAllOpenVacanciesAction(token, requestObjectOpenVacancyList));
        }
        if (recruitingStatusChangedToAbort) {
            dispatch(setUiEncouragementOpenAction(true));
        }
    }, [recruitingStatusChangedToContract, recruitingStatusChangedToAbort]);

    return null;
};

export default ReduxDelegator;
