import { ESortingOptions } from '../../definitions/components.definitions';
import {
    ISetBillingPageActiveTabAction,
    ISetBillingPageAddInvoiceDialogAction,
    ISetBillingPageAddInvoiceItemAction,
    ISetBillingPageAddPaymentForInvoiceAction,
    ISetBillingPageCalculateSumFooterAction,
    ISetBillingPageDeleteInvoiceItemAction,
    ISetBillingPageInvoiceFormAction,
    ISetBillingPageInvoiceGenerationDialogAction,
    ISetBillingPageInvoiceListFastSearchAction,
    ISetBillingPageInvoiceListFilterAction,
    ISetBillingPageInvoiceListFilterMenuAction,
    ISetBillingPageInvoiceListSortingOptionAction,
    ISetBillingPageInvoiceToDeleteAction,
    ISetBillingPageReloadInvoiceListAction,
    ISetBillingPageUpdateInvoiceItemAction,
} from '../../definitions/redux/actions.definition';
import {
    EBillingPageTabs,
    IInvoiceFilter,
    IInvoiceFilterMenu,
    IInvoiceFormState,
    IInvoiceItemForm,
} from '../../definitions/billingPage.definitions';

export const BILLING_PAGE_SET_INVOICE_LIST_FAST_SEARCH = "BILLING_PAGE_SET_INVOICE_LIST_FAST_SEARCH";
export const BILLING_PAGE_SET_INVOICE_LIST_FILTER = "BILLING_PAGE_SET_INVOICE_LIST_FILTER";
export const BILLING_PAGE_SET_INVOICE_LIST_FILTER_MENU = "BILLING_PAGE_SET_INVOICE_LIST_FILTER_MENU";
export const BILLING_PAGE_SET_INVOICE_LIST_SORTING_OPTION = "BILLING_PAGE_SET_INVOICE_LIST__SORTING_OPTION";
export const BILLING_PAGE_SET_ADD_INVOICE_DIALOG = "BILLING_PAGE_SET_ADD_INVOICE_DIALOG";
export const BILLING_PAGE_SET_INVOICE_GENERATION_DIALOG = "BILLING_PAGE_SET_INVOICE_GENERATION_DIALOG";
export const BILLING_PAGE_SET_ADD_PAYMENT_FOR_INVOICE = "BILLING_PAGE_SET_ADD_PAYMENT_FOR_INVOICE";
export const BILLING_PAGE_SET_RELOAD_INVOICE_LIST = "BILLING_PAGE_SET_RELOAD_INVOICE_LIST";
export const BILLING_PAGE_SET_INVOICE_FORM = "BILLING_PAGE_SET_CURRENT_INVOICE";
export const BILLING_PAGE_ADD_INVOICE_ITEM = "BILLING_PAGE_ADD_INVOICE_ITEM";
export const BILLING_PAGE_UPDATE_INVOICE_ITEM = "BILLING_PAGE_UPDATE_INVOICE_ITEM";
export const BILLING_PAGE_DELETE_INVOICE_ITEM = "BILLING_PAGE_DELETE_INVOICE_ITEM";
export const BILLING_PAGE_SET_ACTIVE_TAB = "BILLING_PAGE_SET_ACTIVE_TAB";
export const BILLING_PAGE_CALCULATE_INVOICE_SUM_FOOTER = "BILLING_PAGE_CALCULATE_INVOICE_SUM_FOOTER";
export const BILLING_PAGE_SET_INVOICE_TO_DELETE = "BILLING_PAGE_SET_INVOICE_TO_DELETE";

export const setBillingPageCalculateSumFooterAction = (): ISetBillingPageCalculateSumFooterAction => ({
    type: BILLING_PAGE_CALCULATE_INVOICE_SUM_FOOTER,
});

export const setBillingPageActiveTabAction = (tab: EBillingPageTabs): ISetBillingPageActiveTabAction => ({
    type: BILLING_PAGE_SET_ACTIVE_TAB,
    payload: tab
});

export const setBillingPageReloadInvoiceListAction = (reload: boolean): ISetBillingPageReloadInvoiceListAction => ({
    type: BILLING_PAGE_SET_RELOAD_INVOICE_LIST,
    payload: reload
});

export const setBillingPageInvoiceFormAction = (invoice: Partial<IInvoiceFormState>): ISetBillingPageInvoiceFormAction => ({
    type: BILLING_PAGE_SET_INVOICE_FORM,
    payload: invoice
});

export const setBillingPageAddInvoiceItemAction = (item: Partial<IInvoiceItemForm>): ISetBillingPageAddInvoiceItemAction => ({
    type: BILLING_PAGE_ADD_INVOICE_ITEM,
    payload: item
});

export const setBillingPageUpdateInvoiceItemAction = (index: number, item: Partial<IInvoiceItemForm>): ISetBillingPageUpdateInvoiceItemAction => ({
    type: BILLING_PAGE_UPDATE_INVOICE_ITEM,
    payload: {
        index,
        item
    }
});

export const setBillingPageDeleteInvoiceItemAction = (index: number): ISetBillingPageDeleteInvoiceItemAction => ({
    type: BILLING_PAGE_DELETE_INVOICE_ITEM,
    payload: index
});

export const setBillingPageInvoiceListFilterAction = (filter: Partial<IInvoiceFilter>): ISetBillingPageInvoiceListFilterAction => ({
    type: BILLING_PAGE_SET_INVOICE_LIST_FILTER,
    payload: filter
});

export const setBillingPageInvoiceListFilterMenuAction = (filter: Partial<IInvoiceFilterMenu>): ISetBillingPageInvoiceListFilterMenuAction => ({
    type: BILLING_PAGE_SET_INVOICE_LIST_FILTER_MENU,
    payload: filter
});

export const setBillingPageAddPaymentForInvoiceAction = (invoiceId: number): ISetBillingPageAddPaymentForInvoiceAction => ({
    type: BILLING_PAGE_SET_ADD_PAYMENT_FOR_INVOICE,
    payload: invoiceId
});

export const setBillingPageInvoiceListSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetBillingPageInvoiceListSortingOptionAction => ({
    type: BILLING_PAGE_SET_INVOICE_LIST_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    }
});

export const setBillingPageAddInvoiceDialogAction = (open: boolean): ISetBillingPageAddInvoiceDialogAction => ({
    type: BILLING_PAGE_SET_ADD_INVOICE_DIALOG,
    payload: open
});

export const setBillingPageInvoiceGenerationDialogAction = (open: boolean): ISetBillingPageInvoiceGenerationDialogAction => ({
    type: BILLING_PAGE_SET_INVOICE_GENERATION_DIALOG,
    payload: open
});

export const setBillingPageFastSearchAction = (fastSearchValue: string): ISetBillingPageInvoiceListFastSearchAction => ({
    type: BILLING_PAGE_SET_INVOICE_LIST_FAST_SEARCH,
    payload: fastSearchValue
});

export const setBillingPageInvoiceToDeleteAction = (invoiceId: number): ISetBillingPageInvoiceToDeleteAction => ({
    type: BILLING_PAGE_SET_INVOICE_TO_DELETE,
    payload: invoiceId,
});
