import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDeleteDialog from '../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../selectors/app.selectors';
import { ClientApi } from '../../requests/ClientApi';
import { putMergeCandidateRouteConfig } from '../../requests/routes';
import { getSuggestionAction } from '../../redux/entities/entities.actions';
import { registerError } from '../../redux/error/error.actions';

interface IProps {
    open: boolean;
    candidateId: number;
    mergeCandidateId: number;
    onClose: () => void;
    onSuccess: () => void;
    reloadSuggestionId: number;
}

const CandidateMergeView: React.FC<IProps> = (props) => {
    const open = props.open;
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    if (!open) {
        return null;
    }

    const onSave = () => {
        ClientApi.request(putMergeCandidateRouteConfig, {
            token,
            candidateId: props.candidateId,
            mergeCandidateId: props.mergeCandidateId
        }).then(() => {
            dispatch(getSuggestionAction(token, props.reloadSuggestionId));
            props.onClose();
            props.onSuccess();
        }).catch(() => {
            dispatch(registerError('Es ist ein Fehler aufgetreten'));
        });
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={props.onClose}
            onDelete={onSave}
            titleTranslation={"pages.candidate.mergeCandidateDialog.title"}
            bodyTranslation={"pages.candidate.mergeCandidateDialog.body"}
            deleteTranslation={"pages.candidate.mergeCandidateDialog.button"}
        />
    );
};

export default CandidateMergeView;
