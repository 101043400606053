import React from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setDashboardPageChartSelectionRecruitingsViewOpenAction } from '../../../redux/dashboardPage/dashboardPage.actions';
import ChartSelectionRecruitingRecruitingView from './tables/ChartSelectionRecruitingRecruitingView';
import { translate } from '../../../translation/translate.utils';
import { darker } from '../../../theme/theme';
import CustomDialogBody from '../../../components/CustomDialog/CustomDialogBody';
import EmployeePerformanceChart from './EmployeePerformanceChart';

const ChartSelectionRecruitingsView: React.FC = () => {
    const dispatch = useDispatch();
    const open = useSelector((store: IStore) => store.dashboardPage.chartSelectionRecruitingsViewOpen);
    const handleClose = () => dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(false));
    const chartSelection = useSelector((store: IStore) => store.dashboardPage.chartSelection);

    if (!open) {
        return null;
    }

    let title = '';
    if (chartSelection.month) {
        title += translate('misc.month.' + chartSelection.month) + ' ' + (chartSelection.year || (new Date()).getFullYear());
    } else {
        title += chartSelection.year;
    }

    if (chartSelection.employeeName) {
        title += ' von Mitarbeiter ' + chartSelection.employeeName;
    }

    return (
        <>
            <CustomDialog fullWidth maxWidth={"xl"} onClose={handleClose} open={open}>
                <CustomDialogHeader string={translate('pages.dashboard.recruitingsOf', [
                    title,
                ])} onClose={handleClose} />
                <CustomDialogBody>
                    <div className={"flexContainerRow gap10 overflow"} style={{padding: 5, maxHeight: 800, background: darker}}>
                        <ChartSelectionRecruitingRecruitingView
                            employeeId={chartSelection.employeeId}
                            year={chartSelection.year || (new Date()).getFullYear()}
                            month={chartSelection.month}
                        />
                        {!chartSelection.employeeId &&
                            <div style={{minWidth: 400}}>
                                <EmployeePerformanceChart
                                    year={chartSelection.year || (new Date()).getFullYear()}
                                    month={chartSelection.month}
                                />
                            </div>
                        }
                    </div>
                </CustomDialogBody>
            </CustomDialog>
        </>
    );
};

export default ChartSelectionRecruitingsView;
