import { EIcons, IconFactory } from '../Icons/IconFactory';
import React from 'react';
import { translate } from '../../translation/translate.utils';
import { COLOR_GREEN, COLOR_RED } from '../../theme/theme';

interface IProps {
    value?: null|boolean;
}

const YesNoCellRenderer: React.FC<IProps> = (props) => {
    if (props.value === undefined || props.value === null) {
        return <div>{translate('misc.noInformation')}</div>;
    }

    if (props.value) {
        return (
            IconFactory.getIconComponent(EIcons.Done, { color: COLOR_GREEN })
        );
    }

    return (
        IconFactory.getIconComponent(EIcons.Close, { color: COLOR_RED })
    );
};

export default YesNoCellRenderer;
