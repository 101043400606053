import { setInterval } from 'timers';
import { ClientApi } from '../requests/ClientApi';
import { putNotificationReadRouteConfig } from '../requests/routes';
import { getEmployeeImageUrl } from './ui.utils';

interface IConfetti {
    x: number,
    y: number,
    r: number;
    color: string;
    tilt: number;
    speed: number;
}

interface IBalloon {
    x: number;
    y: number;
    r: number;
    vy: number;
}

const confetti: IConfetti[] = [];
const balloons: IBalloon[] = [];

function createConfetti() {
    const confettiCanvas = document.getElementById('confettiCanvas');
    for (let i = 0; i < 200; i++) {
        confetti.push({
            //@ts-ignore
            x: Math.random() * confettiCanvas.width,
            //@ts-ignore
            y: Math.random() * confettiCanvas.height - confettiCanvas.height,
            r: Math.random() * 6 + 2,
            color: `hsl(${Math.random() * 360}, 100%, 70%)`,
            tilt: Math.random() * 10 - 5,
            speed: Math.random() * 2 + 1,
        });
    }
}
function createBalloons() {
    const balloonCanvas = document.getElementById('confettiCanvas');
    for (let i = 0; i < 20; i++) {
        balloons.push({
            //@ts-ignore
            x: Math.random() * balloonCanvas.width,
            //@ts-ignore
            y: balloonCanvas.height + Math.random() * 100,
            r: Math.random() * 20 + 10,
            vy: Math.random() * 2 + 1,
        });
    }
}
function drawConfetti() {
    const confettiCanvas = document.getElementById('confettiCanvas');
    confettiCanvas.width = window.innerWidth;
    confettiCanvas.height = window.innerHeight;
    //@ts-ignore
    const confettiCtx = confettiCanvas.getContext('2d');

    //@ts-ignore
    confettiCtx.clearRect(0, 0, confettiCanvas.width, confettiCanvas.height);
    confetti.forEach((c) => {
        confettiCtx.beginPath();
        confettiCtx.arc(c.x, c.y, c.r, 0, Math.PI * 2);
        confettiCtx.fillStyle = c.color;
        confettiCtx.fill();
        c.y += c.speed;
        c.tilt += Math.random() * 0.1 - 0.05;
        //if (c.y > confettiCanvas.height) c.y = -c.r;
    });
}

function drawBalloons() {
    const balloonCanvas = document.getElementById('balloonCanvas');
    balloonCanvas.width = window.innerWidth;
    balloonCanvas.height = window.innerHeight;
    //@ts-ignore
    const balloonCtx = balloonCanvas.getContext('2d');

    //@ts-ignore
    balloonCtx.clearRect(0, 0, balloonCanvas.width, balloonCanvas.height);
    balloons.forEach((b, i) => {
        balloonCtx.save();
        balloonCtx.beginPath();
        balloonCtx.arc(b.x + 25, b.y + 25, 25, 0, Math.PI * 2, true);
        balloonCtx.closePath();
        balloonCtx.clip();

        balloonCtx.drawImage(img, b.x, b.y, 50, 50);

        balloonCtx.beginPath();
        balloonCtx.arc(b.x, b.y, 25, 0, Math.PI * 2, true);
        balloonCtx.clip();
        balloonCtx.closePath();
        balloonCtx.restore();

        b.y -= b.vy;
        if (b.y + b.r < 0) balloons.splice(i, 1);
    });
}

export const roundedImage = (ctx, x,y,width,height,radius) => {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
    ctx.lineTo(x + radius, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
    ctx.closePath();
}

const img = new Image();
export const celebrationAnimation = (employeeId: number, notificationId?: number, token?: string) => {
    document.getElementById("basePage").classList.add('vibrate');
    const message = "🎉 You're a Star! 🎉";
    createConfetti();
    drawConfetti();

    function animate() {
        drawConfetti();
        drawBalloons();
        requestAnimationFrame(animate);
    }

    createConfetti();
    createBalloons();

    img.onload = start;
    console.log(employeeId);
    img.src = getEmployeeImageUrl(employeeId);
    function start(){
        animate();
    }

    setInterval(() => {
        document.getElementById("basePage").classList.remove('vibrate');
        if (notificationId && token) {
            ClientApi.request(putNotificationReadRouteConfig, {token: token, id: notificationId})
        }
    }, 6000);
}
