import React from 'react';
import { EEntities, ICandidateEducation, ICandidateExperience } from '../../../../../definitions/entities.definition';
import { themeGreenLight } from '../../../../../theme/theme';
import CustomTable from '../../../../../components/CustomTable/CustomTable';

interface IProps {
    education: ICandidateEducation[];
}

const CandidateEducationList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    color: themeGreenLight.palette.primary.main,
                    entity: EEntities.CandidateProfession,
                    columnConfig: {
                        date: {
                            header: 'Zeitraum',
                            property: "fromYear",
                            width: 140,
                            cellRenderer: (created: string, entity) => {
                                const entityCast = entity as ICandidateExperience;

                                const toString = entityCast.untilToday ? ' bis heute' : entityCast.toMonth ? ' bis ' + entityCast.toMonth.toString().padStart(2, '0') + '/' + entityCast.toYear : '';

                                return entityCast.fromMonth ? entityCast.fromMonth.toString().padStart(2, '0') + '/' + entityCast.fromYear + toString :  entityCast.fromYear + toString;
                            },
                        },
                        title: {
                            header: "Studium/Ausbildung",
                            property: "title",
                            width: 300
                        },
                        school: {
                            header: "Schule",
                            property: "school",
                            flex: 1,
                        },
                        grade: {
                            header: "Note",
                            property: "grade",
                            width: 50
                        },
                    },
                    sortedEntries: props.education,
                }}
            />
        </>
    );
};

export default CandidateEducationList;
