import React from 'react';
import { EEntities, ICandidateCertification } from '../../../../../definitions/entities.definition';
import { themeGreenLight } from '../../../../../theme/theme';
import CustomTable from '../../../../../components/CustomTable/CustomTable';
import moment from 'moment';
import { translate } from '../../../../../translation/translate.utils';

interface IProps {
    certifications: ICandidateCertification[];
}

const CandidateCertificationList: React.FC<IProps> = (props) => {
    return (
        <>
            <CustomTable
                config={{
                    color: themeGreenLight.palette.primary.main,
                    entity: EEntities.CandidateProfession,
                    columnConfig: {
                        date: {
                            header: 'Datum',
                            property: "date",
                            width: 80,
                            cellRenderer: (date?: null|string) => {
                                if (!date) {
                                    return translate('misc.noInformation');
                                }
                                return moment(date).format('MM.Y');
                            }
                        },
                        title: {
                            header: "Beschreibung",
                            property: "title",
                            width: 300
                        },
                        organisation: {
                            header: "Organisation",
                            property: "organisation",
                            flex: 1,
                        },
                        skillCount: {
                            header: "Skills",
                            width: 50,
                            property: "skillCount",
                        }
                    },
                    sortedEntries: props.certifications,
                }}
            />
        </>
    );
};

export default CandidateCertificationList;
