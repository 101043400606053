import React, { useEffect } from 'react';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import RecruitingEditForm from '../../RecruitingPage/components/components/RecruitingEditForm';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import { setRecruitingPageRecruitingToDeclineAction } from '../../../redux/recruitingPage/recruitingPage.actions';
import { getRecruitingAction } from '../../../redux/entities/entities.actions';
import { getToken } from '../../../selectors/app.selectors';

const DeclineRecruitingView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => Boolean(store.recruitingPage.declineRecruitingId));
    const recruitingId = useSelector((store: IStore) => store.recruitingPage.declineRecruitingId);
    const recruiting = useSelector((store: IStore) => store.entities.recruiting.byId[recruitingId]);

    useEffect(() => {
        if (recruitingId> 0 && !recruiting) {
            dispatch(getRecruitingAction(token, recruitingId));
        }
    }, [recruitingId]);

    const onClose = () => {
        dispatch(setRecruitingPageRecruitingToDeclineAction(0));
    };

    if (!open || !recruiting) {
        return null;
    }

    return (
        <CustomDialog maxWidth={"sm"} open={open} onClose={onClose}>
            <CustomDialogHeader string={"Prozess auf Absage stellen"} onClose={onClose} />
            <div className={"padding10"}>
                <RecruitingEditForm recruiting={recruiting} abort/>
            </div>
        </CustomDialog>
    );
};

export default DeclineRecruitingView;
