import React, { ReactNode } from 'react';
import RecruitingCellRenderer from '../../../../components/CustomCellRenderer/RecruitingCellRenderer';
import CompanyContactCellRenderer from '../../../../components/CustomCellRenderer/CompanyContactCellRenderer';
import CandidateCellRenderer from '../../../../components/CustomCellRenderer/CandidateCellRenderer';
import CompanyCellRenderer from '../../../../components/CustomCellRenderer/CompanyCellRenderer';
import SuggestionCellRenderer from '../../../../components/CustomCellRenderer/SuggestionCellRenderer';
import VacancyCellRenderer from '../../../../components/CustomCellRenderer/VacancyCellRenderer';
import { IMailRelation } from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { deleteMailRelationAction } from '../../../../redux/entities/entities.actions';
import { getToken } from '../../../../selectors/app.selectors';

interface IProps {
    relations: IMailRelation[]
}

const MailElementRelation: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const relations = props.relations;
    const token = useSelector(getToken);

    let onDelete = () => console.log(1);
    const elements: ReactNode[] = [];
    const addedKeys: string[] = [];
    let key = '';

    relations.forEach((relation) => {
        if (relation.mailProperty !== 'from' && relation.mailProperty !== 'to' && relation.mailProperty !== 'cc') {
            onDelete = () => {
                dispatch(deleteMailRelationAction(token, relation.id));
            };
        }

        if (relation.recruiting) {
            key = 'recruiting' + relation.recruiting.id;
            if (!addedKeys.includes(key)) {
                elements.push(<RecruitingCellRenderer recruiting={relation.recruiting} showCandidateName onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
        if (relation.companyContact) {
            key = 'companyContact' + relation.companyContact.id;
            if (!addedKeys.includes(key)) {
                elements.push(<CompanyContactCellRenderer companyContact={relation.companyContact} onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
        if (relation.candidate) {
            key = 'candidate' + relation.candidate.id;
            if (!addedKeys.includes(key)) {
                elements.push(<CandidateCellRenderer key={'candidate' + relation.candidate.id} candidate={relation.candidate} onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
        if (relation.company) {
            key = 'company' + relation.company.id;
            if (!addedKeys.includes(key)) {
                elements.push(<CompanyCellRenderer company={relation.company} onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
        if (relation.vacancy) {
            key = 'vacancy' + relation.vacancy.id;
            if (!addedKeys.includes(key)) {
                elements.push(<VacancyCellRenderer vacancy={relation.vacancy} onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
        if (relation.suggestion) {
            key = 'suggestion' + relation.suggestion.id;
            if (!addedKeys.includes(key)) {
                elements.push(<SuggestionCellRenderer recruitingSuggestion={relation.suggestion} onDelete={onDelete}/>);
                addedKeys.push(key);
            }
        }
    })

    return <div className={"flexContainerRow flexWrap gap5"}>
        {elements}
    </div>;
}

export default MailElementRelation;
